import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import InputField from "./InputField";
import { useHistory, useRouteMatch } from "react-router-dom";
import { KIAServicesContext } from "../../contexts/KIAServicesContext";
import Footer from "../common/Footer";
import Nav from "../common/Nav";
import DesktopQR from "../common/DesktopQR";
import TagManager from 'react-gtm-module';

const ZipCodeForm = () => {
	const history = useHistory();
	const { service, setService } = useContext(KIAServicesContext);
	const [zipCode, setZipCode ] = useState('')
  	const [ state, setState ] = useState({
  		initial: false,
  		clicked: null,
  		status: null
  	});
  	const [ st, setSt ] = useState('');
    const match = useRouteMatch('/:name');
    const errorFocus = useRef(null);
    const firstFocus = useRef(null);
    const name =  match.params.name;
  //   const stayPut = useRef(null);
  //   const executeScroll = () => {
  //   	window.scrollTo({
		//   top: stayPut.current.offsetTop-100,
		//   left: 0,
		//   behavior: 'smooth'
		// });
  //   }

	const handleSubmit = (e) => {
		e.preventDefault();

		if (zipCode === '11111') {
			handleError();
			errorFocus.current.focus();
		} else if (zipCode >= 35000 && zipCode <= 36999) {
		   setSt('AL');
		   setState({status:'success'});
		} else if (zipCode >= 99500 && zipCode < 99999) {
		   setSt('AK');
		   setState({status:'success'});
		} else if (zipCode >= 85000 && zipCode <= 86999) {
		   setSt('AZ');
		   setState({status:'success'});
		} else if (zipCode >= 71600 && zipCode <= 72999) {
		   setSt('AR');
		   setState({status:'success'});
		} else if (zipCode >= 90000 && zipCode <= 96699) {
		   setSt('CA');
		   setState({status:'success'});
		} else if (zipCode >= 80000 && zipCode <= 81999) {
		   setSt('CO');
		   setState({status:'success'});
		} else if ((zipCode >= 6000 && zipCode <= 6389) || (zipCode >= 6391 && zipCode <= 6999)) {
		   setSt('CT');
		   setState({status:'success'});
		} else if (zipCode >= 19700 && zipCode <= 19999) {
		   setSt('DE');
		   setState({status:'success'});
		} else if (zipCode >= 32000 && zipCode <= 34999) {
		   setSt('FL');
		   setState({status:'success'});
		} else if ( (zipCode >= 30000 && zipCode <= 31999) || (zipCode >= 39800 && zipCode <= 39999) ) {
		   setSt('GA');
		   setState({status:'success'});
		} else if (zipCode >= 96700 && zipCode <= 96999) {
		   setSt('HI');
		   setState({status:'success'});
		} else if (zipCode >= 83200 && zipCode <= 83999) {
		   setSt('ID');
		   setState({status:'success'});
		} else if (zipCode >= 60000 && zipCode <= 62999) {
		   setSt('IL');
		   setState({status:'success'});
		} else if (zipCode >= 46000 && zipCode <= 47999) {
		   setSt('IN');
		   setState({status:'success'});
		} else if (zipCode >= 50000 && zipCode <= 52999) {
		   setSt('IA');
		   setState({status:'success'});
		} else if (zipCode >= 66000 && zipCode <= 67999) {
		   setSt('KS');
		   setState({status:'success'});
		} else if (zipCode >= 40000 && zipCode <= 42999) {
		   setSt('KY');
		   setState({status:'success'});
		} else if (zipCode >= 70000 && zipCode <= 71599) {
		   setSt('LA');
		   setState({status:'success'});
		} else if (zipCode >= 3900 && zipCode <= 4999) {
		   setSt('ME');
		   setState({status:'success'});
		} else if (zipCode >= 20600 && zipCode <= 21999) {
		   setSt('MD');
		   setState({status:'success'});
		} else if( (zipCode >= 1000 && zipCode <= 2799) || (zipCode === 5501) || (zipCode === 5544 ) ) {
		   setSt('MA');
		   setState({status:'success'});
		} else if (zipCode >= 48000 && zipCode <= 49999) {
		   setSt('MI');
		   setState({status:'success'});
		} else if (zipCode >= 55000 && zipCode <= 56899) {
		   setSt('MN');
		   setState({status:'success'});
		} else if (zipCode >= 38600 && zipCode <= 39999) {
		   setSt('MS');
		   setState({status:'success'});
		} else if (zipCode >= 63000 && zipCode <= 65999) {
		   setSt('MO');
		   setState({status:'success'});
		} else if (zipCode >= 59000 && zipCode <= 59999) {
		   setSt('MT');
		   setState({status:'success'});
		} else if (zipCode >= 27000 && zipCode <= 28999) {
		   setSt('NC');
		   setState({status:'success'});
		} else if (zipCode >= 58000 && zipCode <= 58999) {
		   setSt('ND');
		   setState({status:'success'});
		} else if (zipCode >= 68001 && zipCode <= 69367) {
		   setSt('NE');
		   setState({status:'success'});
		} else if (zipCode >= 88900 && zipCode <= 89999) {
		   setSt('NV');
		   setState({status:'success'});
		} else if (zipCode >= 3000 && zipCode <= 3899) {
		   setSt('NH');
		   setState({status:'success'});
		} else if (zipCode >= 7000 && zipCode <= 8999) {
		   setSt('NJ');
		   setState({status:'success'});
		} else if (zipCode >= 87000 && zipCode <= 88499) {
		   setSt('NM');
		   setState({status:'success'});
		} else if ( (zipCode >= 10001 && zipCode <= 14999) || (zipCode === 6390) || (zipCode === 501) || (zipCode === 544) ) {
		   setSt('NY');
		   setState({status:'success'});
		} else if (zipCode >= 43000 && zipCode <= 45999) {
		   setSt('OH');
		   setState({status:'success'});
		} else if ((zipCode >= 73000 && zipCode <= 73199) || (zipCode >= 73400 && zipCode <= 74999) ) {
		   setSt('OK');
		   setState({status:'success'});
		} else if (zipCode >= 97000 && zipCode <= 97999) {
		   setSt('OR');
		   setState({status:'success'});
		} else if (zipCode >= 15000 && zipCode <= 19699) {
		   setSt('PA');
		   setState({status:'success'});
		} else if (zipCode >= 300 && zipCode <= 999) {
		   setSt('PR');
		   setState({status:'success'});
		} else if (zipCode >= 2800 && zipCode <= 2999) {
		   setSt('RI');
		   setState({status:'success'});
		} else if (zipCode >= 29000 && zipCode <= 29999) {
		   setSt('SC');
		   setState({status:'success'});
		} else if (zipCode >= 57000 && zipCode <= 57999) {
		   setSt('SD');
		   setState({status:'success'});
		} else if (zipCode >= 37000 && zipCode <= 38599) {
		   setSt('TN');
		   setState({status:'success'});
		} else if ( (zipCode >= 75000 && zipCode <= 79999) || (zipCode >= 73301 && zipCode <= 73399) ||  (zipCode >= 88500 && zipCode <= 88599) ) {
		   setSt('TX');
		   setState({status:'success'});
		} else if (zipCode >= 84000 && zipCode <= 84999) {
		   setSt('UT');
		   setState({status:'success'});
		} else if (zipCode >= 5000 && zipCode <= 5999) {
		   setSt('VT');
		   setState({status:'success'});
		} else if ( (zipCode >= 20100 && zipCode <= 20199) || (zipCode >= 22000 && zipCode <= 24699) || (zipCode === 20598) ) {
		   setSt('VA');
		   setState({status:'success'});
		} else if ( (zipCode >= 20000 && zipCode <= 20099) || (zipCode >= 20200 && zipCode <= 20599) || (zipCode >= 56900 && zipCode <= 56999) ) {
		   setSt('DC');
		   setState({status:'success'});
		} else if (zipCode >= 98000 && zipCode <= 99499) {
		   setSt('WA');
		   setState({status:'success'});
		} else if (zipCode >= 24700 && zipCode <= 26999) {
		   setSt('WV');
		   setState({status:'success'});
		} else if (zipCode >= 53000 && zipCode <= 54999) {
		   setSt('WI');
		   setState({status:'success'});
		} else if (zipCode >= 82000 && zipCode <= 83199) {
		   setSt('WY');
		   setState({status:'success'});
		} else {
		  handleError();
		}
	}

    const handleSuccess = useCallback(() => {
    	setService({...service, st: st, zip: zipCode, locationLoaded: true});
    	TagManager.dataLayer({ dataLayer: { event: 'Submit form - Zipcode Success' } });
        history.push(`/${name}/results`);
    },[history, name, service, setService, st, zipCode])

    const handleRedirect = useCallback(() => {
        history.push(`/${name}`);
    },[history, name])

    const handleError = useCallback(() => {
    	setState({...state, status:'error', initial : null})
    },[])

	useEffect(()=> {
		// window.scrollTo({top: 0, behavior: 'smooth'});
		if (state.status === 'success'){
			handleSuccess();
		}
		if(service.offerType === ''){
			handleRedirect();
		}
		if(service.locationLoaded === false){
			handleError();
		}
	},[handleSuccess, handleRedirect, handleError, service.offerType, service.locationLoaded])

	useEffect(() => {
		if(service.locationLoaded === false){
			errorFocus.current.focus();
		} else {
			firstFocus.current.focus();
		}
	},[])


	// useEffect(()=> {
	// 	if(state.initial === null && service.locationLoaded === false){
	// 		console.log('JuMP!!')
	// 		setState({...state, status: null});
	// 		setService({...service, locationLoaded: null})
	// 	}
	// },[state.initial])


	return (
		<>
		<Nav />
		<div className="zipCodeFormSection">
			<textarea ref={firstFocus} readOnly rows="2" value="Enter your zip code to view local offers" ></textarea>	

			<div className="mc__field-container">
				<form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
		          <InputField
		            onChangeHandler={setZipCode}
		            type="tel"
		            value={zipCode}
		            placeholder="zip code"
		            name="zipCode"
		            isRequired
		          />
		          <InputField
		            label="Submit"
		            type="submit"
		            formValues={[zipCode]}
		          />
		        </form>
		        <input className={state.status === 'error' ? 'errorMsg show' : 'errorMsg'} ref={errorFocus} readOnly value="Invalid Zip Code" />
			</div>
		</div>
		<Footer />
		<DesktopQR />
		</>
	)
}

export default ZipCodeForm;