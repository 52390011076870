import React, { useEffect, useContext, useRef } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import { gsap, ScrollTrigger } from "gsap/all";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Data } from "../../data.js";
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

const Footer = props => {

	const { displayModal } = useContext(ModalContext);
	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	const data = Data[0];
	//const history = useHistory();
 	const socialInfo = data[name].footerLinks;
 	const singleName = data[name].offerParams.smName;
 	const modelYear = data[name].offerParams.modelYear;
 	const footerInfo = data[name].offerDetails.links;
 	const available = data[name].offerDetails.available;
 	const reserve = data[name].offerDetails.reservationLink;
 	const btmDraw = useRef(null);

 	let location = useLocation();
 	const toggleDarkMode = location.pathname === `/${name}` ? 'darkModeFooter' : 
 							location.pathname === '/' ? 'darkModeFooter' :
 							location.pathname === `/${name}/offers` ? 'lightModeFooter' :
 							location.pathname === `/${name}/zipcodeform` ? 'lightModeFooter' :
 							location.pathname === `/${name}/locate` ? 'lightModeFooter' :
 							location.pathname === `/${name}/results` ? 'lightModeFooter' : null

	const toggleIconColor = location.pathname !== `/${name}` ? '#05141F' : '#fff';
	const toggleFooterColor = location.pathname !== `/${name}` ? '#C4C4C4' : '#fff';

	// const handleFooterHomeLink = () => {
	// 	window.scrollTo({top: 0, behavior: 'smooth'});
	// 	history.push(`/${name}`);
	// }

    useEffect(() => {
    	const el = btmDraw.current;

    	if(location.pathname === `/${name}`){
			gsap.fromTo(el, {drawSVG:'0'}, { duration: 1.5, drawSVG:'100%', ease: 'back.inOut(1.7)', scrollTrigger: { trigger: el} });
		} else {
			return () => {
				//gsap.set(el, {drawSVG:'0'});
	    	}
    	}
    	// eslint-disable-next-line
    },[]);

	return (
		<footer className={toggleDarkMode} aria-hidden={displayModal} >

			{ location.pathname !== '/' ?

			<>
			<div className="motifContainer">
				<svg version="1.1" id="footer" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
					 viewBox="0 0 393 66">

				<g id="Group_59" transform="translate(0 29.673)">
					<g>
						<g id="Group_60" transform="translate(0 -29.673)">
							<g id="Group_59-2" transform="translate(0.158 0.305)">
								<path id="btmLine" ref={btmDraw} className="motifLine" fill="none" strokeWidth="0.15%" stroke={toggleFooterColor} d="M1.5,64.2l80.8-49.1c17.7-10.7,28.6-13.9,49-13.9h260"/>
							</g>
						</g>
					</g>
				</g>
				</svg>
			</div>
			<div className="listContainer">
				<p className="footerModelName" aria-hidden="true">{`The ${modelYear} ${singleName}`}</p>
				{ available === true ?
					<Link to={`/${name}/offers`}>
						View Local Offers
					</Link> : null
				}

				{ reserve !== "" && available === false ?
					<a aria-hidden={displayModal} target="_blank" rel="noreferrer" tabIndex="0" href={`${reserve}`}>
					Reserve Now
					</a>
				: null}

				<a href={`${footerInfo.pdf}`} rel="noreferrer" target="_blank" >Download PDF Brochure</a>
				<a href={`https://www.kia.com/us/en/${name}`} rel="noreferrer" target="_blank" >Visit kia.com/{`${name.toLowerCase()}`}</a>
				<a href={`${footerInfo.signUp}`} rel="noreferrer" target="_blank" >Sign Up for Updates</a>
			</div>

			

			<div className="socialContainer">

				<a href={socialInfo.facebook} title="Facebook" rel="noreferrer" target="_blank" className="facebook">
					<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
					  <g id="Group_85" data-name="Group 85" transform="translate(-1024 599)">
					    <path id="logo" data-name="Path 40" d="M1047-587.5a11.5,11.5,0,0,1-9.7,11.36v-8.036h2.68l.51-3.324H1037.3v-2.157a1.662,1.662,0,0,1,1.874-1.8h1.45v-2.83a17.682,17.682,0,0,0-2.574-.225c-2.627,0-4.344,1.592-4.344,4.474v2.533h-2.92v3.324h2.92v8.036a11.5,11.5,0,0,1-9.7-11.36,11.5,11.5,0,0,1,11.5-11.5A11.5,11.5,0,0,1,1047-587.5Z" transform="translate(0)" fill={toggleIconColor}/>
					  </g>
					</svg>
				</a>
				<a href={socialInfo.instagram} title="Instagram" rel="noreferrer" target="_blank" className="instagram">
					<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
					  <g id="Group_84" data-name="Group 84" transform="translate(-4.079 -4.079)">
					    <path id="logo" data-name="Path 38" d="M15.579,6.151c3.071,0,3.434.012,4.647.067a6.365,6.365,0,0,1,2.135.4A3.809,3.809,0,0,1,24.544,8.8a6.365,6.365,0,0,1,.4,2.135c.055,1.213.067,1.576.067,4.647S25,19.013,24.94,20.226a6.365,6.365,0,0,1-.4,2.135,3.809,3.809,0,0,1-2.183,2.183,6.365,6.365,0,0,1-2.135.4c-1.212.055-1.576.067-4.647.067S12.144,25,10.932,24.94a6.365,6.365,0,0,1-2.135-.4,3.809,3.809,0,0,1-2.183-2.183,6.365,6.365,0,0,1-.4-2.135c-.055-1.213-.067-1.576-.067-4.647s.012-3.434.067-4.647a6.365,6.365,0,0,1,.4-2.135A3.809,3.809,0,0,1,8.8,6.614a6.365,6.365,0,0,1,2.135-.4c1.213-.055,1.576-.067,4.647-.067m0-2.072c-3.123,0-3.515.013-4.741.069a8.441,8.441,0,0,0-2.792.535A5.881,5.881,0,0,0,4.683,8.046a8.441,8.441,0,0,0-.535,2.792c-.056,1.227-.069,1.618-.069,4.741s.013,3.515.069,4.741a8.441,8.441,0,0,0,.535,2.792,5.881,5.881,0,0,0,3.363,3.363,8.441,8.441,0,0,0,2.792.535c1.227.056,1.618.069,4.741.069s3.515-.013,4.741-.069a8.441,8.441,0,0,0,2.792-.535,5.881,5.881,0,0,0,3.363-3.363,8.441,8.441,0,0,0,.535-2.792c.056-1.227.069-1.618.069-4.741s-.013-3.515-.069-4.741a8.441,8.441,0,0,0-.535-2.792,5.881,5.881,0,0,0-3.363-3.363,8.441,8.441,0,0,0-2.792-.535c-1.227-.056-1.618-.069-4.741-.069Z" fill={toggleIconColor}/>
					    <path id="logo" data-name="Path 39" d="M132.34,126.635a5.705,5.705,0,1,0,5.705,5.705A5.705,5.705,0,0,0,132.34,126.635Zm0,9.408a3.7,3.7,0,1,1,3.7-3.7A3.7,3.7,0,0,1,132.34,136.043Z" transform="translate(-116.761 -116.761)" fill={toggleIconColor}/>
					    <circle id="logo" data-name="Ellipse 6" cx="1.429" cy="1.429" r="1.429" transform="translate(20.258 8.041)" fill={toggleIconColor}/>
					  </g>
					</svg>
				</a>
				<a href={socialInfo.twitter} title="Twitter" rel="noreferrer" target="_blank" className="twitter">
					<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
						<path id="logo" d="M13.8,9.8L22,0.3h-1.9L13,8.5L7.3,0.3H0.7l8.6,12.5l-8.6,10h1.9l7.5-8.7l6,8.7h6.6L13.8,9.8L13.8,9.8z
						 M11.2,12.9l-0.9-1.2L3.4,1.7h3l5.6,8l0.9,1.2l7.3,10.4h-3L11.2,12.9L11.2,12.9z" fill={toggleIconColor}/>
					</svg>
				</a>
				<a href={socialInfo.youtube} title="Youtube" rel="noreferrer" target="_blank" className="youtube">
					<svg xmlns="http://www.w3.org/2000/svg" width="33" height="23" viewBox="0 0 33 23">
					  <g id="Group_83" data-name="Group 83" transform="translate(-8 -34)">
					    <path id="logo" data-name="Path 37" d="M39.963,37.591a4.1,4.1,0,0,0-2.886-2.9C34.532,34,24.323,34,24.323,34s-10.209,0-12.754.686a4.1,4.1,0,0,0-2.886,2.9A43.014,43.014,0,0,0,8,45.5a43.014,43.014,0,0,0,.683,7.909,4.1,4.1,0,0,0,2.886,2.9C14.114,57,24.323,57,24.323,57s10.209,0,12.754-.686a4.1,4.1,0,0,0,2.886-2.9,43.014,43.014,0,0,0,.683-7.909A43.014,43.014,0,0,0,39.963,37.591ZM20.984,50.354V40.646L29.516,45.5Z" fill={toggleIconColor}/>
					  </g>
					</svg>
				</a>
				
			</div>
			<div className="termsPrivacyCont" >
				<a href={socialInfo.terms} rel="noreferrer" target="_blank">Terms of Service</a>
				<a href={socialInfo.privacy} rel="noreferrer" target="_blank">Privacy Policy</a>
			</div></> :

			<>
			</> }
			
		</footer>
	)

}


export default Footer;