import React, { useContext, useState, useEffect } from "react";
import { KIAServicesContext } from "../../contexts/KIAServicesContext";
import { Data } from "../../data.js";
import { useRouteMatch } from 'react-router-dom';

const OfferMenu = () => {
	const { service, setService } = useContext(KIAServicesContext);

	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	const data = Data[0];
	const offerInfo = data[name].offerParams.offerTypes;
	const [isOpen, setOpen] = useState(false);
	const [label, setLabel] = useState("");

	// useEffect(()=> {
	// 	if(label === ''){
	// 		setService({...service, offerType: '1' });
	// 		setLabel('Finance');
	// 	}
	// }, [service, setService, setLabel])


	useEffect(()=> {
		if(service.offerType === '1'){
			setLabel('Finance');
		}
		if(service.offerType === '2'){
			setLabel('Lease');
		}
		if(service.offerType === '3'){
			setLabel('Cash');
		}
		if(service.offerType === '13'){
			setLabel('Military');
		}
		if(service.offerType === ''){
			setLabel('Select Offer');
		}

	}, [service, setService, setLabel])


	return (
		<div className="accordion-wrapper" tabIndex="0">

			<button className={`accordion-title ${isOpen ? "open" : ""}`} aria-expanded={isOpen} onClick={() => setOpen(!isOpen)} >

        		<span className="offerBtnName">{label}</span>
        		<span className={`arrowContainer ${isOpen ? "open" : ""}`}></span>

      		</button>

			<div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>

				<div className="accordion-content">
					{
						Object.entries(offerInfo).map(([key, value], index) => {
							return (
			                  <button key={index} disabled={!isOpen} className={service.offerType === value ? 'btnContainer selected' :'btnContainer'} 
			                  		onClick={(e) => setService({...service, offerType: value }, 
			                  						setLabel(key), 
			                  						setOpen(!isOpen),
			                  						window.scrollTo({top: 0, behavior: 'smooth'})
			                  					)} 
			                  		>
			                  	<span className="offerBtnName">{key}</span>
			                  </button>
							);
						})
					}

				</div>
			</div>
		</div>
	)
}

export default OfferMenu;