import React, { useState, createContext } from "react";

export const ModalContext = createContext();

export const ModalContextProvider = props => {
  const [displayModal, setDisplayModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(10);

  const bodyTag = document.getElementsByTagName('body')[0].classList;
  //const htmlTag = document.getElementsByTagName('html')[0].classList;

  const toggleModal = (e) => {
    e.preventDefault();
    const position = window.pageYOffset;
    setScrollPosition(position);

    e.preventDefault();
    setDisplayModal(!displayModal);
    bodyTag.contains('no-scroll') ? bodyTag.remove('no-scroll')
    : bodyTag.add('no-scroll');

    // htmlTag.contains('no-scroll') ? htmlTag.remove('no-scroll')
    // : htmlTag.add('no-scroll');
  };

  return ( 
    <ModalContext.Provider value={{ displayModal, setDisplayModal, toggleModal, scrollPosition, setScrollPosition }}>
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalContextProvider;