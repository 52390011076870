import React from "react";
import { useRouteMatch } from "react-router-dom";
// import KIALogo from '../../images/global/KIAlogo.svg';
import { Data } from "../../data.js";

const DesktopQR = props => {

	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	const data = Data[0];
	const socialInfo = data[name].footerLinks;

	return (
			<>
				<div className="qrContainer">
					<div className="ctaQRContainer">
						<img src={process.env.PUBLIC_URL + `/images/${name}/qr.jpg`} alt="qr code" />
						<p>This experience is designed for a mobile device.<br/> 
							Please scan the QR code using your phone.
						</p>
						<img src={process.env.PUBLIC_URL + `/images/global/KIAlogo.svg`} alt="qr" />
					</div>
				<div className="termsContainer">
					<a href={socialInfo.terms} rel="noreferrer" target="_blank">Terms of Service</a>
					<a href={socialInfo.privacy} rel="noreferrer" target="_blank">Privacy</a>
				</div>

				</div>

			</>
	)

}




export default DesktopQR;