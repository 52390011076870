import React, { useContext, useLayoutEffect, useRef } from "react";
import { Data } from "../../data.js";
import { KIAServicesContext } from "../../contexts/KIAServicesContext";
import { useRouteMatch } from "react-router-dom";
import { gsap } from "gsap/all";

const Cash = props => {

	const { beginDate, endDate, details, disclaimer } = props.data;
	const { service } = useContext(KIAServicesContext);
	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	const offerCta = Data[0][name].offerDetails.links;
	const offerWord = details.length === 1 ? 'offer' : 'offers';
	const cashModule = useRef();

	useLayoutEffect(() => {
            gsap.fromTo(cashModule.current, { autoAlpha: 0, y: 10 }, { duration: 0.75, autoAlpha: 1, y: 0, ease: 'back.inOut(1.7)' });
	},[details])

	return (
		<div className="cashSection" ref={cashModule}>
			<div>
				<p className="tallyOffer" tabIndex="0">{`${details.length} ${offerWord}`} in <span className="zipId">{`${service.zip}`}</span></p>
				{	details.map((detail, index)=> {	
						return (
							<div key={index} className="offerModule" >
								<h4 className="offerDesc" tabIndex="0">{`${details[index].description}`}</h4>
								<p className="offerDate" tabIndex="0">{`Offer valid from: ${beginDate} - ${endDate}`}</p>
								<div className="ctaContainer">
									<a href={`${offerCta.getQuote}zipCode=${service.zip}&seriesId=${service.modelId}&year=${service.modelYear}`} rel="noreferrer" target="_blank" >
										<button className="quoteCta">Get a Quote</button>
									</a>
									<a href={`${offerCta.inventory}zipCode=${service.zip}&seriesId=${service.modelId}&year=${service.modelYear}`} rel="noreferrer" target="_blank" >
										<button className="inventoryCta">View Inventory</button>
									</a>
								</div>
							</div>
						)
					})
				}
			</div>
			<div className="discContainer">
				<p tabIndex="0">Disclaimer:</p>
				<div tabIndex="0" dangerouslySetInnerHTML={{ __html: disclaimer }} />
			</div>
		</div>
	)

}


export default Cash;