import React, { useState, useEffect, useContext } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import { useRouteMatch} from "react-router-dom";
import { Data } from "../../data.js";
// import TagManager from 'react-gtm-module';

const StickyNav = props => {

	const { displayModal } = useContext(ModalContext);
	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	const data = Data[0];
	const { reference } = props;
	//const history = useHistory();
	//let location = useLocation();
	const singleName = data[name].offerParams.smName;
	const modelYear = data[name].offerParams.modelYear;
	//const toggleLogoColor = location.pathname !== `/${name}` ? '#000' : '#fff';
	const [scrolled, setScrolled] = useState(false);

	const handleNavClick = (() => {
		// TagManager.dataLayer({ dataLayer: { event: 'Nav btn - Home' } });
		// history.push(`/${name}`);
		window.scrollTo({top: 0, behavior: 'smooth'});
	})

	const handleScroll = () => {
		const offset = window.scrollY;
	    if(offset > 600 ){
	      setScrolled(true);
	    }
	    else{
	      setScrolled(false);
	    }
	}

	useEffect(() => {
	    window.addEventListener('scroll',handleScroll);
	    return () => {
            setScrolled({});
        }
	},[])

	return (
			<>
				<div className={ scrolled ? "darkModeNav sticky" : "darkModeNav"} ref={reference} id="stickyNav" aria-hidden={displayModal}>
					<div>
						<div className="navBtn" title="Home button" onClick={handleNavClick}>
							<svg xmlns="http://www.w3.org/2000/svg" width="76" height="18" viewBox="0 0 76 18">
							  <path id="logo" fill="#fff"/*{toggleLogoColor}*/ data-name="Kia Logo Black" d="M43.932,15.775c0,.14.043.22.126.22a.344.344,0,0,0,.183-.07L67.841.6A2.275,2.275,0,0,1,69.2.187h5.187a1.257,1.257,0,0,1,1.33,1.33v9.9a2.488,2.488,0,0,1-1.329,2.527L68.1,17.727a.418.418,0,0,1-.211.078c-.076,0-.147-.059-.147-.274V5.972c0-.126-.042-.222-.128-.222a.356.356,0,0,0-.182.068L50.177,17.024a2.268,2.268,0,0,1-1.324.408H37.416a1.257,1.257,0,0,1-1.33-1.33V1.83c0-.1-.046-.2-.126-.2a.364.364,0,0,0-.185.069L24.395,8.534c-.114.068-.145.125-.145.18s.019.095.1.175l8.127,8.127c.108.109.183.191.183.276s-.119.14-.258.14H25.047a1.779,1.779,0,0,1-1.331-.4l-4.928-4.928a.207.207,0,0,0-.143-.078.317.317,0,0,0-.152.053l-8.245,4.954a2.316,2.316,0,0,1-1.331.4H1.33A1.257,1.257,0,0,1,0,16.1V6.38A2.482,2.482,0,0,1,1.33,3.858L7.66.055A.324.324,0,0,1,7.831,0c.1,0,.148.1.148.32v13c0,.137.038.2.126.2a.4.4,0,0,0,.188-.079L29.682.585a2.318,2.318,0,0,1,1.411-.4H42.6a1.257,1.257,0,0,1,1.33,1.33Z"/>
							</svg>	
						</div>		
					</div>
					<div className="nameContainer" >
						<p className="footerModelName" tabIndex="-1" aria-hidden="true">{`The ${modelYear} ${singleName}`}</p>
					</div>
				</div>
			</>
	)
}

export default StickyNav;