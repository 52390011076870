import React, { useContext, useEffect, useRef, useState, useLayoutEffect, useCallback} from "react";
import { useRouteMatch} from 'react-router-dom';
import { KIAServicesContext } from "../../contexts/KIAServicesContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { gsap, ScrollTrigger } from "gsap/all";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";
import Header from "../common/Header";
import Links from "../common/Links";
import Media from "../common/Media";
import StickyNav from "../common/StickyNav";
import Loader from '../common/Loader';
import Intro from '../common/Intro';
import { Data } from "../../data.js";
import Footer from "../common/Footer";
import DesktopQR from "../common/DesktopQR";

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

const ModelHome = props => {

	const { complete, setComplete } = useContext(LoaderContext);
	const { service, setService } = useContext(KIAServicesContext);
	///
	//const { data, name } = props;
	// new match variables
	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	const data = Data[0];

	const scrollToDiv = (ref) => window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'});
	const carInfo = data[name];
	const headerInfo = data[name].header;
	const linkInfo = data[name].links;
	const mediaInfo = data[name].media;
	const award = data[name].links.awards;
	const introVideo = data[name].modelIntro;
	const disclaimer = data[name].offerDetails.disclaimer;
 	const linksRef = useRef();
 	const homeFade = useRef();
 	//gsap states
	const [ loadingState, setLoadingState ] = useState('start');

	// load the loader then trigger video start or fade home page

	useEffect(()=> {			
		window.scrollTo({top: 0, behavior: 'smooth'});
		let timer = setTimeout(() => setLoadingState(introVideo.intro === true && complete.intro === false ? 'video' : 'complete'), `${complete.time}000`);

		setService({...service, offerType: '', st: '', zip: '', modelYear: '',locationLoaded: null, modelName: '', modelId: ""});
        return () => {
			clearTimeout(timer);
        }

	},[setService, complete]);

	// fade in the home page after the loader or video ends

	useLayoutEffect(() => {
		if(loadingState === "complete"){
			setComplete({'time': '', 'amount': ''});
			// if(){
            	gsap.fromTo(homeFade.current, { autoAlpha: 0 }, { duration: 0.75, autoAlpha: 1, ease: 'back.inOut(1.7)'});
            // }
		}
	},[loadingState, setComplete])


	const handleIntro = useCallback(()=> {
		setLoadingState('complete');
		setComplete({...complete, 'intro': true});
	}, [setLoadingState, setComplete])




	return (
			<>
				<div className={loadingState === 'start' ? 'filler' : 'modelHome homeSection'} id={name} ref={homeFade}>

					<Header click={()=> scrollToDiv(linksRef)} data={headerInfo} loadStatus={loadingState} />
					<StickyNav data={data[name]} />
					<Links scrollLink={linksRef} data={linkInfo} carId={carInfo}/>
					<Media data={mediaInfo} disclaimer={disclaimer} award={award}/>
					<Footer />
					
					{ (loadingState === "start" || loadingState === "video") && introVideo.intro === true && complete.intro === false ?
					<Intro trigger={handleIntro} status={loadingState} /> : null }

					{ loadingState === "start" ?
					<Loader /> : null			
					}

				</div>
				<DesktopQR />
			</>
	)
}

export default ModelHome;