import React, { useContext, useState, useEffect, useRef } from "react";
import { KIAServicesContext } from "../../contexts/KIAServicesContext";
import { Data } from "../../data.js";
import { useRouteMatch } from 'react-router-dom';

const CarMenu = () => {

	// console.log("hello")
	const { service, setService } = useContext(KIAServicesContext);
	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	const data = Data[0];
	const carList = data[name].carListMenu;

	const [isOpen, setOpen] = useState(false);
	const firstInput = useRef(null);

	

	useEffect(() => {
		// console.log("listened to service change", inputElement.current);
		if(carList.length === 1){
			setService({...service, modelId: carList[0].id, modelName: name, modelYear: carList[0].modelYear });
			// console.log(carList.length, service)
		} else {
			firstInput.current.focus();
			// 	return () => {
			// }
		}
		// eslint-disable-next-line
	}, [service.offerType]);

	return (

		<>

		{ carList.length === 1 ?
		

		<div className="accordion-one">
			<div className="blankContainer">
					<img src={`images/${name}/menupics/${carList[0].id}.png`} alt="" />
			    	<span>{carList[0].title}</span>
			</div>
		</div> :


		<div className="accordion-wrapper">

			<button ref={firstInput} className={`accordion-title ${isOpen ? "open" : ""}`} onClick={() => setOpen(!isOpen)} >
				{service.modelId === "" ?
						<> 
						<img src={`images/${name}/menupics/default.png`} alt="" />
			    		<span>Select Model</span>
			    		<span className={`arrowContainer ${isOpen ? "open" : ""}`}></span>
			    		</>
						:
						<>
						<img src={`images/${name}/menupics/${service.modelId}.png`} alt="" />
			    		<span>{service.modelName}</span>
			    		<span className={`arrowContainer ${isOpen ? "open" : ""}`}></span>
			    		</>
		    		
	    		}
      		</button>

			<div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>

				<div className="accordion-content">
					{carList.map((car, index )=>{
						return(
							<button key={index} disabled={!isOpen} className={service.modelName === car.title ? 'btnContainer selected' :'btnContainer'} 
												onClick={(e) =>  
												setService({...service, modelId: car.id, modelName: car.title, modelYear: car.modelYear }, 
												setOpen(!isOpen)
												//window.scrollTo({top: 0, behavior: 'smooth'})
											)} 
								>
								<img src={car.image} alt={`${car.title} thumbnail`} />
								<span>{car.title}</span>
							</button>
						)
					})
					}

				</div>
			</div>
		</div>

		}
		</>
	)
}

export default CarMenu;