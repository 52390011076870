import React from "react";
import { Link } from "react-router-dom";
// import KIALogo from '../../images/global/KIAlogo.svg';
//import { LoaderContext } from "../../contexts/LoaderContext";
import TagManager from 'react-gtm-module';
import { Data } from "../../data.js";


TagManager.dataLayer({
  dataLayer: {
    event: 'pageview',
    pagePath: '/',
    pageTitle: 'KIA Brochure - Home',
  },
});

const Home = () => {

	// const { complete, setComplete } = useContext(LoaderContext);
	const data = Data[0];

	return (
		<section className="home">
			<div>
				<img	src={process.env.PUBLIC_URL + `/images/global/KIAlogo.svg`} alt="KIA logo"/>
			</div>
			<div className="linkContainer">
			{
				Object.entries(data).map(([index]) => {
					return (
						<Link key={index} to={`/${index}`}>{`View ${index}`}</Link>
					)
				})
			}
			</div>
		</section>
	)
}

export default Home;