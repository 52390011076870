import React, { useState, createContext } from "react";

export const LoaderContext = createContext();

export const LoaderContextProvider = props => {

  const [ complete, setComplete ] = useState({
        'time': 4,
        'amount': '100%',
        'intro': false
  })

  return ( 
    <LoaderContext.Provider value={{ complete, setComplete  }}>
      {props.children}
    </LoaderContext.Provider>
  )
}

export default LoaderContextProvider;