import React, { useEffect, useRef, useState } from "react";
import { Data } from "../../data.js";
import { useRouteMatch } from "react-router-dom";

const Intro = (props) => {

  const { status, trigger } = props;
  const match = useRouteMatch("/:name");
  const name = match.params.name;
  const data = Data[0];
  const introVideo = data[name].modelIntro;
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const [video, setVideo] = useState(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    setVideo(videoElement);

    const videoContainer = videoContainerRef.current;

    const handleVideoEnd = () => {
      if (videoContainer) {
        videoContainer.style.display = "none";
        trigger();
      }
    };

    if (videoElement) {
      videoElement.addEventListener("ended", handleVideoEnd);

      // Initialize the video source based on the initial orientation
      const portraitVideoSource = introVideo.portraitUrl;
      const landscapeVideoSource = introVideo.landscapeUrl;
      const videoSource = window.matchMedia("(orientation: portrait)").matches
        ? portraitVideoSource
        : landscapeVideoSource;

      videoElement.src = videoSource;
      videoElement.load();

      if (status === "start") {
        videoElement.pause();
      }

      if (status === "video") {
        videoElement.play();
      }
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, [status]);

  // Add an event listener to handle orientation changes
  useEffect(() => {
    const handleOrientationChange = () => {
      if (video && video.src) {
        const portraitVideoSource = introVideo.portraitUrl;
        const landscapeVideoSource = introVideo.landscapeUrl;
        const videoSource = window.matchMedia("(orientation: portrait)").matches
          ? portraitVideoSource
          : landscapeVideoSource;

        video.src = videoSource;
        video.load();
        if (status === "video") {
          video.play();
        }
      }
    };

    window.addEventListener("resize", handleOrientationChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, [status, video, introVideo]);

  return (
    <div className="introContainer">
      <div className="video-container" ref={videoContainerRef}>
        <video ref={videoRef} preload="auto" playsInline muted>
          <source src="" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Intro;
