import React from "react";
import { HashRouter, Route, Switch} from "react-router-dom";
import './styles/css/main.css';
import ScrollToTop from "./hooks/ScrollToTop";
import Home from "./components/pages/Home";
import ModelHome from "./components/pages/ModelHome";
import Offers from "./components/pages/Offers";
import Results from "./components/pages/Results";
import Locate from "./components/pages/Locate";
import ZipCodeForm from "./components/pages/ZipCodeForm";

const App = (props) => {

  return (
    <div className="app">
      <HashRouter>
        <ScrollToTop />
          <Switch>
            <Route exact path="/" render={()=> (
              <Home />
            )} />
            <Route exact path="/:name" render={()=> (
              <ModelHome  />
            )} />
            <Route exact path="/:name/offers" render={()=> (
              <Offers  />
            )} />
            <Route exact path="/:name/locate" render={()=> (
              <Locate />
            )} />
            <Route exact path="/:name/zipcodeform" render={()=> (
              <ZipCodeForm />
            )} />
            <Route exact path="/:name/results" render={()=> (
              <Results />
            )} />
         </Switch> 
      </HashRouter>
    </div>
  );
}

export default App;
   
