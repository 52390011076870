import React from "react";
// import Modal from "../common/Modal";

const InputField = props => {
  //Checks if all the fields are filled
  // const validateInput = values => {
  //   if (values.some(f => f === "")) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

let dim = null;

const validateInput = (values) => {
  if (!values || !/^\d{5}$/.test(values)) {
    dim = true;
    return true; 
  } else {
    dim = false;
    return false;
  }
};


if (props.type === "submit") {
    return (
      <input
        tabIndex="0"
        className='submit'
        type='submit'
        value={props.label}
        disabled={validateInput(props.formValues)}
        className={dim === true ? 'submitDim' : 'submit'}
      />
    )
  } else if (props.type === "tel") {
      return(
        <label tabIndex="0" className="inputField__label">
          {props.label}
          <input
            onChange={(e) => props.onChangeHandler(e.target.value.replace(/\D/g, ""))}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            required={props.isRequired}
            className="fields"
            name={props.name}
            maxLength={5}
            forhtml="phone"
            pattern="[0-9]{5}"
          />
        </label>
      )

  }

}


  export default React.memo(InputField);