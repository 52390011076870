import React, { useEffect, useContext, useRef } from "react";
import { gsap } from "gsap/all";
// import KIALogo from '../../images/global/KIAloader.svg';
import { LoaderContext } from "../../contexts/LoaderContext";


const Loader = props => {

	const { complete } = useContext(LoaderContext);
	const bodyTag = document.getElementsByTagName('body')[0].classList;
	const bar = useRef();

	useEffect(() => {
	    bodyTag.contains('no-scroll') ? bodyTag.remove('no-scroll')
	    : bodyTag.add('no-scroll');

		gsap.to(bar.current, { duration: complete.time, width: complete.amount, ease:'back.Out' });
		return () => {
			bodyTag.remove('no-scroll');
        }
	},[complete, bodyTag])


	return (
		<div className="loaderContainer">
			<div className="graphicContainer">
				<img src={process.env.PUBLIC_URL + `/images/global/KIAloader.svg`} alt="Loading"/>
				<div className="barContainer">
					<div className="bar" ref={bar}></div>
				</div>
			</div>
		</div>
	)
}

export default Loader;