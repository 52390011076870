import React, { useContext, useState, useEffect, useCallback } from "react";
import axios from 'axios';
import { useHistory, useRouteMatch } from "react-router-dom";
import { KIAServicesContext } from "../../contexts/KIAServicesContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import Loader from '../common/Loader';
import TagManager from 'react-gtm-module';

const Locate = props => {

    const { complete, setComplete } = useContext(LoaderContext);
    const history = useHistory()
    const { service, setService } = useContext(KIAServicesContext);
    const API_KEY = process.env.REACT_APP_GEO_KEY;
    const source = axios.CancelToken.source();
    const match = useRouteMatch('/:name');
    const name =  match.params.name;

    const [location, setLocation] = useState({
        loaded: ''
    });
    
    const handleZipCode = useCallback(() => {
        TagManager.dataLayer({ dataLayer: { event: 'Geolocate Failed - Zipcode form' } });
        history.replace(`/${name}/zipcodeform`);
    },[history, name])

    const handleResults = useCallback(() => {
        TagManager.dataLayer({ dataLayer: { event: 'Geolocate Success - Results' } });
        history.replace(`/${name}/results`);
    },[history, name])

    const handleRedirect = useCallback(() => {
        history.push(`/${name}/offers`);
    },[history, name])

    const handleSuccess = useCallback((location) => {
        let zip, state;
        setComplete({'time': 0.25, 'amount': '100%'});
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coords.latitude},${location.coords.longitude}&key=${API_KEY}`,  { cancelToken: source.token })
          .then(function (response) {
            // handle success
            // console.log(response.data)

            zip = response.data.results[0].address_components[6].long_name;
            state = response.data.results[0].address_components[4].short_name;

            //console.log(zip);
            //console.log(state)

            if(!zip.match(/\b\d{5}\b/g) || !state.match(/^[A-Z]{2}$/)){
                handleZipCode();
            } else {
                
                setService({...service, st: state, zip: zip, locationLoaded: true});
                handleResults();
            }
          })
          .catch(function (error) {
            // handle error
            // console.log(error);
            handleZipCode();
            setLocation({...location, loaded: false});
            //history.push(`${name}/zipcodeform`);
          })
          .then(function () {
          });
    },[handleResults, handleZipCode, service, setComplete, setService, API_KEY, source]);

    const handleError = useCallback((error) => {
        setComplete({'time': 1, 'amount': '100%'})
        setLocation({
            loaded: false,
            error: {
                code: error.code,
                message: error.message,
            }
        });
        handleZipCode();
    },[setComplete, handleZipCode]);

    useEffect(() => {
        if(service.type === "" ){
            handleRedirect();
        };

        setComplete({...complete, 'time': 1, 'amount': '60%'});
        navigator.geolocation.getCurrentPosition(handleSuccess, handleError);

        return () => {
            source.cancel();
            setLocation({});
        }

    }, []);

	return (
		<>
		  <Loader />
		</>
	)
}

export default Locate;



