import React, { useEffect, useRef, useContext } from "react";
import { gsap } from "gsap/all";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";
import { useRouteMatch } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { ModalContext } from "../../contexts/ModalContext";

gsap.registerPlugin(DrawSVGPlugin);

const Header = props => {

	const { displayModal } = useContext(ModalContext);
	const { tagline, awards } = props.data;
	const motif = useRef();
	const arrowLoop = useRef();
	const arrowContainer = useRef();
	const copyContainer = useRef();
	const kiaLogo = useRef();
	const headerGroup = useRef();
	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	// const [height, setHeight] = useState(null);

	const handleLearnMore = (() => {
		TagManager.dataLayer({ dataLayer: { event: 'Learn More' } });
		props.click();
	})


    useEffect(() => {
    	// if(window.innerHeight <= 414 ){
    	// 	gsap.set('.tagContainer', { y: -195 } );
    	// }
    	gsap.set(headerGroup.current, { y: -10});
    	if(props.loadStatus === "complete"){
            gsap.fromTo(copyContainer.current, { autoAlpha: 0, y: 10 }, { delay: 0.5, duration: 1.5, autoAlpha: 1, y: 0, ease: 'back.inOut(1.7)' });
            gsap.fromTo(kiaLogo.current, { autoAlpha: 0 }, { delay: 1, duration: 1.5, autoAlpha: 1, ease: 'back.inOut(1.7)' });
            gsap.fromTo(arrowContainer.current, { autoAlpha: 0 }, { delay: 2.5, duration: 1.5, autoAlpha: 1, ease: 'back.inOut(1.7)' });
            // gsap.set(vee, {strokeDashArray: l});
            gsap.fromTo(motif.current, {drawSVG:'0'}, {delay: 1, duration: 1.5, drawSVG:'100%', ease: 'back.inOut(1.7)'})
            gsap.fromTo(arrowLoop.current, { y: 5 }, { delay: 4.5, duration: 1.5, y: 0, repeat: -1, yoyo: true, ease: 'back.inOut(1.7)' });
           }
    }, [props.loadStatus]);

	// useEffect(() => {
	// 	if(height === null){
	// 	  setHeight(window.innerWidth)
	// 	}
	// 	const updateWindowDimensions = () => {
	// 	  setHeight(window.innerHeight)
	// 	}
	// 	window.addEventListener('resize', updateWindowDimensions)
	// 	return () => window.removeEventListener('resize', updateWindowDimensions)
	// }, [height, setHeight])


	// style={{height: `${height}px`}} 

	return (
		<header aria-hidden={displayModal} tabIndex="0" className={`${name}Header`}>

			<div className="tagContainer">
				<svg version="1.1" ref={headerGroup} xmlns="http://www.w3.org/2000/svg" x="0px"
					 y="0px" viewBox="0 0 1200 1030">

				<g id="tag" transform="translate(20 450.895)">
					<g>
						<g ref={kiaLogo} transform="translate(0 -51.895)">
							<g id="Group_1" transform="translate(0 0)">
								<path id="Path_4" fill="#fff" d="M95.3,567.3c0,0.3,0.2,0.5,0.3,0.5c0.2,0,0.3,0,0.5-0.2l57.2-37.2c1-0.7,2.1-1,3.3-1H169
									c1.6-0.2,3.1,1.1,3.3,2.8c0,0.2,0,0.2,0,0.3v23.9c0.3,2.5-1,4.9-3.3,6.1l-15.2,9.2c-0.2,0.2-0.3,0.2-0.5,0.2s-0.3-0.2-0.3-0.7
									v-28c0-0.3-0.2-0.5-0.3-0.5s-0.3,0-0.5,0.2l-41.8,27.2c-1,0.7-2.1,1-3.3,1H79.4c-1.6,0.2-3.1-1.1-3.3-2.8c0-0.2,0-0.2,0-0.3
									v-34.6c0-0.3-0.2-0.5-0.3-0.5c-0.2,0-0.3,0-0.5,0.2l-27.5,16.6c-0.3,0.2-0.3,0.3-0.3,0.5s0,0.2,0.2,0.5l19.7,19.7
									c0.3,0.3,0.5,0.5,0.5,0.7s-0.3,0.3-0.7,0.3H49.4c-1.1,0.2-2.3-0.2-3.3-1l-12-12c-0.2-0.2-0.2-0.2-0.3-0.2s-0.3,0-0.3,0.2l-20,12
									c-1,0.7-2.1,1-3.3,1H-8c-1.6,0.2-3.1-1.1-3.3-2.8c0-0.2,0-0.2,0-0.3v-23.6c-0.3-2.5,1-4.9,3.3-6.1l15.4-9.2
									c0.2-0.2,0.3-0.2,0.5-0.2s0.3,0.2,0.3,0.8v31.5c0,0.3,0.2,0.5,0.3,0.5c0.2,0,0.3-0.2,0.5-0.2l51.8-31.1c1-0.7,2.1-1,3.4-1h27.9
									c1.6-0.2,3.1,1.1,3.3,2.8c0,0.2,0,0.2,0,0.3v34.6h-0.1V567.3z"/>
							</g>
							<g id="Group_2" transform="translate(0 34.441)">
								<path id="Path_5" fill="#fff" d="M-11.3,551h1.5l4.8,11.1l4.7-11.1h1.5v13.4h-1.3v-11l-4.8,11.1h-0.5l-4.8-11.1v11.1h-1.1V551z"
									/>
							</g>
							<g id="Group_3" transform="translate(8.917 36.597)">
								<path id="Path_6" fill="#fff" d="M-1.1,561.7c2,0,3.1-1.5,3.1-3.9v-0.3c0-2.5-1.1-3.9-3.1-3.9s-3.1,1.5-3.1,3.9v0.3
									C-4.2,560.2-3.1,561.7-1.1,561.7 M-5.6,557.4c0-3.1,1.8-4.9,4.4-4.9s4.4,2,4.4,4.9v0.3c0,3.1-1.8,4.9-4.4,4.9s-4.4-2-4.4-4.9
									V557.4z"/>
							</g>
							<g id="Group_4" transform="translate(14.551 36.759)">
								<path id="Path_7" fill="#fff" d="M-0.7,552.5l3.1,8.4l3.1-8.4h1.1L3,562.1H1.5l-3.8-9.7h1.6C-0.7,552.4-0.7,552.5-0.7,552.5z"/>
							</g>
							<g id="Group_5" transform="translate(20.228 36.597)">
								<path id="Path_8" fill="#fff" d="M8.7,557c0-2.5-1.3-3.6-2.8-3.6c-1.6,0-2.9,1.3-2.9,3.6H8.7z M1.7,557.3
									c-0.3-2.5,1.5-4.6,3.9-4.9c0.2,0,0.2,0,0.3,0c2.5,0,4.1,1.6,4.1,4.9v0.5H3c0,2.1,1.1,3.8,3.1,3.8c1.3,0,2.5-0.5,3.4-1.5v1.3
									c-1,0.8-2.1,1.1-3.4,1.1c-2.8,0-4.4-2-4.4-4.9C1.7,557.6,1.7,557.3,1.7,557.3z"/>
							</g>
							<g id="Group_6" transform="translate(26.403 36.597)">
								<path id="Path_9" fill="#fff" d="M5.6,552.7h1l0.2,2c0.7-1.3,1.8-2.1,3.3-2.3c1.3-0.2,2.6,1,2.8,2.3c0.7-1.3,2-2.3,3.4-2.3
									c1.8,0,2.8,1.1,2.8,3.4v6.6H18V556c0-1.6-0.5-2.6-1.8-2.6c-1.5,0.3-2.8,1.5-3.1,2.9v6.1H12V556c0-1.6-0.5-2.6-1.8-2.6
									c-1.5,0.3-2.8,1.5-3.1,2.9v6.1H5.8v-9.7H5.6z"/>
							</g>
							<g id="Group_7" transform="translate(35.693 36.597)">
								<path id="Path_10" fill="#fff" d="M18.6,557c0-2.5-1.3-3.6-2.8-3.6c-1.6,0-2.9,1.3-2.9,3.6H18.6z M11.5,557.3
									c-0.3-2.5,1.5-4.6,3.9-4.9c0.2,0,0.2,0,0.3,0c2.5,0,4.1,1.6,4.1,4.9v0.5h-7c0,2.1,1.1,3.8,3.1,3.8c1.3,0,2.5-0.5,3.4-1.5v1.3
									c-1,0.8-2.1,1.1-3.4,1.1c-2.8,0-4.4-2-4.4-4.9C11.5,557.6,11.5,557.3,11.5,557.3z"/>
							</g>
							<g id="Group_8" transform="translate(41.868 36.597)">
								<path id="Path_11" fill="#fff" d="M15.5,552.7h1l0.2,2.3c0.7-1.5,2.1-2.5,3.6-2.5c2,0,3.1,1.3,3.1,3.6v6.4h-1.1v-6.2
									c0-1.8-0.5-2.8-2-2.8c-1.8,0.3-3.1,1.6-3.4,3.3v5.6h-1.3L15.5,552.7L15.5,552.7L15.5,552.7z"/>
							</g>
							<g id="Group_9" transform="translate(47.349 34.961)">
								<path id="Path_12" fill="#fff" d="M19,554.3h2.1v-2.9h1.1v2.9h2.1v1h-2.1v8.7h-1.1v-8.7H19V554.3z"/>
							</g>
							<g id="Group_10" transform="translate(54.053 34.961)">
								<path id="Path_13" fill="#fff" d="M23.3,554.3h2.1v-2.9h1.1v2.9h2.1v1h-2.1v8.7h-1.1v-8.7h-2.1V554.3z"/>
							</g>
							<g id="Group_11" transform="translate(58.255 34.376)">
								<path id="Path_14" fill="#fff" d="M26,550.9h1.1v6.1c0.7-1.5,2-2.3,3.6-2.5c2,0,3.1,1.3,3.1,3.6v6.4h-1.1v-6.2
									c0-1.8-0.5-2.8-2-2.8c-1.6,0.3-3.1,1.6-3.4,3.3v5.7H26V550.9z"/>
							</g>
							<g id="Group_12" transform="translate(64.05 36.597)">
								<path id="Path_15" fill="#fff" d="M32.8,561.7c1.5-0.2,2.8-1,3.4-2.3v-2.1l-1.8,0.3c-1.3,0.3-2.6,0.5-3.3,1.1
									c-0.2,0.3-0.3,0.7-0.3,1.1c0,1,0.7,1.8,1.5,1.8C32.6,561.7,32.6,561.7,32.8,561.7 M30.2,558.3c0.5-0.8,1.6-1.1,3.6-1.5l2.6-0.5
									c0-1.8-0.8-2.8-2.6-2.8c-1.3,0-2.5,0.5-3.3,1.5v-1.1c1-0.8,2.1-1.1,3.3-1.1c1.8-0.3,3.6,1,3.8,2.8c0,0.2,0,0.5,0,0.7v6.4h-0.8
									l-0.3-1.6c-0.8,1.1-2.3,2-3.8,2c-1.5,0.2-2.8-0.8-2.9-2.3c0-0.2,0-0.3,0-0.5C29.7,559.2,29.8,558.8,30.2,558.3"/>
							</g>
							<g id="Group_13" transform="translate(69.482 34.961)">
								<path id="Path_16" fill="#fff" d="M33.1,554.3h2.1v-2.9h1.1v2.9h2.1v1h-2.1v8.7h-1.1v-8.7h-2.1V554.3z"/>
							</g>
							<path id="Path_17" fill="#fff" d="M114.8,589.3h1.1v9.7h-1.1V589.3z M114.8,585.7h1.1v1.8h-1.1V585.7z"/>
							<g id="Group_14" transform="translate(79.123 36.597)">
								<path id="Path_18" fill="#fff" d="M39.3,552.7h1l0.2,2.3c0.7-1.5,2.1-2.5,3.6-2.5c2,0,3.1,1.3,3.1,3.6v6.4H46v-6.2
									c0-1.8-0.5-2.8-2-2.8c-1.8,0.3-3.1,1.6-3.4,3.3v5.6h-1.3V552.7z"/>
							</g>
							<g id="Group_15" transform="translate(85.045 36.597)">
								<path id="Path_19" fill="#fff" d="M43.1,560.1c0.7,1,1.8,1.5,2.9,1.5c1.5,0,2.3-0.7,2.3-1.6c0-0.7-0.2-1.3-1.5-1.8l-1.5-0.7
									c-1.5-0.7-2.3-1.5-2.3-2.6c0-1.3,1.1-2.5,3.1-2.5c1.1,0,2.1,0.3,2.9,1.1v1.3c-0.8-0.8-1.8-1.5-2.9-1.5c-1.3,0-2,0.7-2,1.5
									c0,0.7,0.5,1.1,1.6,1.5l1.5,0.7c1.6,0.7,2.1,1.5,2.1,2.8c0,1.6-1.5,2.8-3.6,2.8c-1.1,0-2.1-0.3-2.9-1.1v-1.3h0.3
									C43.1,560.2,43.1,560.1,43.1,560.1z"/>
							</g>
							<g id="Group_16" transform="translate(90.173 36.597)">
								<path id="Path_20" fill="#fff" d="M50.6,561.7c1.8,0,3.1-1.6,3.1-4.1s-1.3-4.1-3.1-4.1c-1.5,0.2-2.8,1.3-3.1,2.8v2.8
									C48,560.4,49.1,561.5,50.6,561.7 M46.3,552.7h1l0.2,1.8c0.7-1.1,2-2,3.3-2c2.5,0,4.3,2,4.3,5.1s-1.8,5.1-4.3,5.1
									c-1.3,0-2.6-0.8-3.3-2v5.6h-1.1v-13.6L46.3,552.7L46.3,552.7z"/>
							</g>
							<path id="Path_21" fill="#fff" d="M147.1,589.3h1.1v9.7h-1.1V589.3z M147.1,585.7h1.1v1.8h-1.1V585.7z"/>
							<g id="Group_17" transform="translate(98.752 36.597)">
								<path id="Path_22" fill="#fff" d="M51.8,552.7h1l0.2,1.8c0.5-1.1,1.6-2,2.9-2c0.3,0,0.7,0,1,0.2v1c-0.3-0.2-0.7-0.2-1.1-0.2
									c-1.3,0.2-2.5,1.1-2.8,2.5v6.6h-1.1v-9.9L51.8,552.7L51.8,552.7z"/>
							</g>
							<g id="Group_18" transform="translate(102.116 36.597)">
								<path id="Path_23" fill="#fff" d="M61,557c0-2.5-1.3-3.6-2.8-3.6c-1.6,0-2.9,1.3-2.9,3.6H61z M54,557.3
									c-0.3-2.5,1.5-4.6,3.9-4.9c0.2,0,0.2,0,0.3,0c2.5,0,4.1,1.6,4.1,4.9v0.5h-7c0,2.1,1.1,3.8,3.1,3.8c1.3,0,2.5-0.5,3.4-1.5v1.3
									c-1,0.8-2.1,1.1-3.4,1.1c-2.8,0-4.4-2-4.4-4.9C54,557.6,54,557.3,54,557.3z"/>
							</g>
							<g id="Group_19" transform="translate(107.96 36.597)">
								<path id="Path_24" fill="#fff" d="M57.7,560.1c0.7,1,1.8,1.5,2.9,1.5c1.5,0,2.3-0.7,2.3-1.6c0-0.7-0.2-1.3-1.5-1.8l-1.5-0.7
									c-1.5-0.7-2.3-1.5-2.3-2.6c0-1.3,1.1-2.5,3.1-2.5c1.1,0,2.1,0.3,2.9,1.1v1.3c-0.8-0.8-1.8-1.5-2.9-1.5c-1.3,0-2,0.7-2,1.5
									c0,0.7,0.5,1.1,1.6,1.5L62,557c1.6,0.7,2.1,1.5,2.1,2.8c0,1.6-1.5,2.8-3.6,2.8c-1.1,0-2.1-0.3-2.9-1.1v-1.3h0.1
									C57.7,560.2,57.7,560.1,57.7,560.1z"/>
							</g>
						</g>
					</g>
				</g>
				<g id="motif" transform="translate(0 489.6)">
					<g>
						<g id="Group_23">
							<g id="Group_22" transform="translate(0.385 -542.044)">
								<path id="motif" ref={motif} stroke="#fff" fill="none" d="M8.4,75.1V946c0,9.8,2.8,14.6,9.2,14.6c4.3,0,8.4-2.6,13.6-5.7L1175.4,266"/>
							</g>
						</g>
					</g>
				</g>
				</svg>
			</div>
			<div className="copyContainer" ref={copyContainer}>
				<div tabIndex="0" aria-hidden={displayModal} dangerouslySetInnerHTML={{ __html:tagline }} />
			</div>
			<div className={ awards.length === 1 ? "btmHeaderContainer awardPresent" : "btmHeaderContainer awardNone"}>
				{
				awards.length === 1 ? 
				<div className="awardHdrContainer">
					{
						awards.map((index) => {
							return (
								<img src={`${index}`} key={index} alt="award" />
							)
						})
					}
				</div> : null
				}
				<div className="arrowContainer" ref={arrowContainer} onClick={handleLearnMore}>
					<p className={awards.length === 1 ? " lmAwardPresent " : " lmAwardNone "} aria-hidden="true" tabIndex="-1" onClick={handleLearnMore}>Learn More</p>
					<button ref={arrowLoop} aria-hidden="true" tabIndex="0" title="Learn more" onClick={handleLearnMore}></button>
				</div>
			</div>
		</header>
	)

}


export default Header;