import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import axios from 'axios';
import { useRouteMatch, useHistory } from "react-router-dom";
import { KIAServicesContext } from "../../contexts/KIAServicesContext";
import { ModalContext } from "../../contexts/ModalContext";
//import Offer from "../types/Offer";
import Finance from "../types/Finance";
import Lease from "../types/Lease";
import Cash from "../types/Cash";
import Military from '../types/Military';
// import Arrow from '../../images/global/arrowback.svg';
import Loader from '../common/Loader';
import Footer from "../common/Footer";
import Nav from "../common/Nav";
import { Data } from "../../data.js";
import CarMenu from "../common/CarMenu";
import OfferMenu from "../common/OfferMenu";
import DesktopQR from "../common/DesktopQR";

const Results = props => {

  //const source = axios.CancelToken.source();
  const { service, setService } = useContext(KIAServicesContext);
  const { setScrollPosition } = useContext(ModalContext);
  const history = useHistory();
  const match = useRouteMatch('/:name');
  const name =  match.params.name;
  const info = Data[0];
  const inputElement = useRef(null);
  const noOffer = useRef(null);
  const singleName = info[name].offerParams.smName;

  const [data, setData] = useState({
    'offer': '',
    'loaded': null,
    'offerBtn': false,
    'ariaBtn': true,
    'offerName': ''
  });

  const ctaButton = info[name].offerDetails.links;
  const apiInfo = info[name].offerParams;
  const apIurl = `${apiInfo.apiUrl}langcode=${apiInfo.langcode}&seriesid=${service.modelId}&modelYear=${service.modelYear}&type=${service.offerType}&state=${service.st}&zipcode=${service.zip}`


  const apiGETrequest = useCallback(() => {
        axios.get(apIurl)
          .then(function (response) {
            // handle success
            // console.log(service.offerType, " " + service.modelId + " GET")
            if( service.offerType === '1'){
              setData({...data, offer: response.data, offerName: 'finance', offerBtn: true});
            }
            if ( service.offerType === '2'){
              setData({...data, offer: response.data, offerName: 'lease', offerBtn: true});
            }
            if ( service.offerType === '3' ){
              setData({...data, offer: response.data, offerName: 'cash', offerBtn: true});
            }
            if ( service.offerType === '13'){
              setData({...data, offer: response.data, offerName: 'military', offerBtn: true});
            }
          })
          .catch(function (error) {
            // console.log(error.response);
            // handle error
            setData({...data, loaded: false})
          })
          .then(function () {
            // always executed
        });
  },[apIurl, data, service.offerType])


//call KIA API callback

	const getKIAData = useCallback(() => {
    // console.log("on-load render")
    apiGETrequest();
    return () => {
    }
   },[apiGETrequest]);

// handle 'go back to kIA ...'

  const handleBackBtn = useCallback(()=> {
    setScrollPosition(0)
    history.push(`/${name}`);
  }, [name, setScrollPosition])

// check it there's a zip and state or else go back to home page

  const handleInventory = () => {
    window.open(`${ctaButton.inventory}zipCode=${service.zip}&seriesId=${service.modelId}&year=${apiInfo.modelYear}`);
  }

  const handleQuote = () => {
    window.open(`${ctaButton.getQuote}zipCode=${service.zip}&seriesId=${service.modelId}&year=${apiInfo.modelYear}`)
  }

  useEffect(() => {
    if( service.locationLoaded === false || service.locationLoaded === null){
      history.push(`/${name}`);
    } else {
      getKIAData();
    }
    return () => {
    }
  }, [service.locationLoaded, history, name]);

//check if GET request failed and try again entering zipcode
  useEffect(() => {
    if(data.loaded === false){
      setService({...service, locationLoaded: false});
      history.replace(`/${name}/zipcodeform`);
    }
    return () => {
    }
  }, [data.loaded, history, name])

//focus to "view offers" button after selection
  useEffect(() => {
    // console.log("FOCUS SUBMIT");
    inputElement.current.focus();
    setData({...data, offerBtn: false});

    return () => {
    }
  }, [service.offerType, service.modelId]);


// focus to "no offers available" message
  useEffect(() => {
    if(data.offer.id === null){
      noOffer.current.focus();
    }
    return () => {
    }
  },[data.offer.id])




	return (
    <>
    <Nav/>

		<div className="resultsSection">
      <h3 className="offerTitle" tabIndex="0">Local offers for</h3>
      <CarMenu/>
      <OfferMenu/>
      <div className="offerContainerResults">
        <div>
          <p>For more offers visit</p>
          <a href="http://kia.com/offers" target="_blank" rel="noreferrer" tabIndex="0" ><p>kia.com/offers</p></a>
        </div>
        <button tabIndex="0" ref={inputElement} className={data.offerBtn === true ? 'offerBtnDisable' : 'offerBtnActive'} disabled={data.offerBtn} type="button" 
          onClick={(e) => apiGETrequest() } 
        >
          View Offers
        </button>
      </div>
      <div>
      { data.offerName === 'finance'  && data.offer.id !== null ?

        <Finance  data={data.offer} /> :

        data.offerName === 'lease' && data.offer.id !== null ?

        <Lease data={data.offer} /> :

        data.offerName === 'cash' && data.offer.id !== null ?

        <Cash data={data.offer} /> :

        data.offerName === 'military' && data.offer.id !== null ?

        <Military data={data.offer} /> :

        data.offer.id === null ?

        <div className="noOfferContainer">
          <p className="noOffersCopy" tabIndex="0" ref={noOffer}>{`There are currently no ${data.offerName} offers in your area. Explore some of our other offers.`} </p>
          <div className="ctaContainer">
            <button onClick={handleQuote} tabIndex="-1" className="quoteCta">
              Get a Quote
            </button>
            <button onClick={handleInventory} tabIndex="-1" className="inventoryCta">
              View Inventory
            </button>
          </div>
        </div> :
        <Loader />
        }

      </div>
      <div className="btmNav">
        <div className="backBtmBtn">
          <div onClick={(e) => handleBackBtn()}>
            <img src={process.env.PUBLIC_URL + `/images/global/arrowback.svg`} alt="" /><span>{`Back to Kia ${singleName}`}</span>
          </div>
        </div>
      </div>

		</div>
    <Footer/>
    <DesktopQR />
    </>
	)

}


export default Results;