import React, { useContext, useRef, useEffect, useCallback } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import YouTube from '@u-wave/react-youtube';
import Disclaimer from "../common/Disclaimer";

const Modal = (props) => {

  const { displayModal, setDisplayModal, toggleModal } = useContext(ModalContext);
  const { onClick, type, mediaId, caption } = props;
  const bodyTag = document.getElementsByTagName('body')[0].classList;
  //const inputElement = useRef(null);
  const mediaCloseBtn = type === 'image' || 'disclaimer' ? 'customBtnImage' : 'customBtnVideo';
  const captionTag = caption;
  const cleanCaption = captionTag.replace(/\<[^)]*\>/gi, "");

  const clickOutside = (e) => {
    if(e.target.classList.contains('modal-container')) {
      setDisplayModal(false);
      bodyTag.remove('no-scroll');
    } 
  }

  /// Pinch to zoom
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);


  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  };

  useEffect(() => {
    //inputElement.current.focus();
    if(type === ''){
      onClick();
    }
    return () => {
      
    }
  }, [type]);

  return (
  <div className={displayModal ? 'modal-container' : 'modal-container hidden'} onClick={clickOutside}>
      <div className="modal">
        <input type="text" className="inputFake" readOnly tabIndex="0" />
        <div tabIndex="0" className="main-content">

        { type === 'video' ? 

        <div className="video-container" >

            <YouTube
              video={mediaId}
              modestBranding={true}
              playsInline={true}
              showCaptions={true}
              width="100%"
              height="100%"
              autoplay={true}
              showRelatedVideos={false}
              onReady={_onReady}
            />

        </div> : <></> }

        { type === 'mp4Loop' ?

        <div className="mp4-wrapper">
          <video autoPlay playsInline width='100%' muted loop poster="">
          <source src={mediaId} type="video/mp4" />
          </video>
        </div> : <></> }

        { type === 'image' ?

        <div className="image-container">
          <div className="pinchZoomContainer">
            <p className="pinchCopy" >Pinch to Zoom</p>
          </div>
          <QuickPinchZoom onUpdate={onUpdate} maxZoom={4} minZoom={0.5}>
            <img className="pinchImage" tabIndex="0" ref={imgRef} alt={cleanCaption} src={mediaId} />
          </QuickPinchZoom>

        </div> : <></>

        }

        { type === "disclaimers" ?

          <Disclaimer data={mediaId} /> :
          <></>
        }

      </div>

      <button className={mediaCloseBtn} title="Close modal" tabIndex="0" onClick={onClick} onKeyPress={(e) => e.key === 'Enter' ? toggleModal : null} ></button >

      </div>

    </div>
  )
}

export default Modal;