import React, { useState, createContext } from "react";

export const KIAServicesContext = createContext();

export const KIAServicesContextProvider = props => {

  const [ service, setService ] = useState({
          'offerType': '',
          'st': '',
          'zip': '',
          'locationLoaded': null,
          'modelName':'',
          'modelId': '',
          'modelYear': ''
  });

  return ( 
    <KIAServicesContext.Provider value={{ service, setService }}>
      {props.children}
    </KIAServicesContext.Provider>
  )
}

export default KIAServicesContextProvider;