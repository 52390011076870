import React, { useEffect, useRef } from "react";
import { gsap } from "gsap/all";

const Disclaimer = props => {
const focusDisclaimer = useRef(null);
const paragraph = props.data;

useEffect (() => {
	focusDisclaimer.current.focus();
	return () => {
    }
},[focusDisclaimer])

useEffect (()=> {
	window.scrollTo({top: 0, behavior: 'smooth'});
	gsap.set(focusDisclaimer.current, { y: 70 });
},[])

function addTabs() {
	let tabAddData = paragraph.replace(/<p>/g,'<p tabIndex="0">');
	return {__html: tabAddData};
}

	return (
		<section  className="discModalContainer" >
			<div tabIndex="1" id="focusTag" ref={focusDisclaimer} dangerouslySetInnerHTML={addTabs()} />
		</section>
	)
}

export default Disclaimer;

