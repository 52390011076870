import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import KIAServicesProvider from './contexts/KIAServicesContext';
import LoaderProvider from './contexts/LoaderContext';
import ModalContextProvider from "./contexts/ModalContext";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-PBPMHHC'
}
 
TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  <React.StrictMode>
  <LoaderProvider>
    <KIAServicesProvider>
      <ModalContextProvider>
        <App />
      </ModalContextProvider>
    </KIAServicesProvider>
    </LoaderProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
