import React, { useContext } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { ModalContext } from "../../contexts/ModalContext";


const Links = props => {

	const match = useRouteMatch('/:name');
	const name =  match.params.name;
	const { displayModal } = useContext(ModalContext);

	return (
		<section className="linksSection" ref={props.scrollLink} id="links" >
			<ul >
				

					{ props.data.available === true ?
					<li>
						<Link aria-hidden={displayModal} to={`/${name}/offers`} tabIndex="0">
							View Local Offers
						</Link>
					</li> : null }

					{ props.data.releaseDate !== "" && props.data.available === false ?
						<li><p className="releaseCopy">{`${props.data.releaseDate}`}</p></li>
					: null}

					{ props.data.reservationLink !== "" && props.data.available === false ?
						<li>
							<a aria-hidden={displayModal} target="_blank" rel="noreferrer" tabIndex="0" href={`${props.data.reservationLink}`}>
							Reserve Now
							</a>
						</li>	
					: null}
				
				<li>
					<a aria-hidden={displayModal} target="_blank" rel="noreferrer" tabIndex="0" href={`${props.data.pdf}`}>
						Download PDF Brochure
					</a>
				</li>
				<li>
					<a aria-hidden={displayModal} className="mmNameCase" target="_blank" rel="noreferrer" tabIndex="0" href={`${props.data.kiaUrl}${name}`}>
						Visit kia.com/{`${name}`}
					</a>
				</li>
			</ul>
		</section>
	)
}

export default Links;