
export const Data =  [

{

	"forte": {
		"modelIntro": {
			"intro": false,
			"portraitUrl": "",
			"landscapeUrl": "",
		},
		"offerParams": {
			// Autoever API GET endpoint
			"apiUrl": "https://services.kia.com/KiaRestLite/rest/offers/getIncentiveByModelAndState.jsonp?",
			"langcode": "en",
			// Full Title of multi model type
			"smName":"forte",
			"modelYear": "2022",
			// Offer types with the associated id's used in the API call.
			"offerTypes": {
				"Finance": "1",
				"Lease": "2",
				"Cash": "3",
				"Military": "13"
			}
		},
		"header": {
			"heroImage": process.env.PUBLIC_URL + "/images/forte/homehero.jpg",
			"tagline":"The new 2022 Forte.<br/>Tech-loaded.<br/>Thrill-ready.",
			"awards": []
		},
		"links": {
			"available": true,
			"releaseDate": "",
			"pdf": "https://www.kia.com/content/dam/kia/us/brochures/forte_2022_brochure.pdf",
			"kiaUrl": "https://www.kia.com/us/en/",
			"awards": []
		},
		//This is the array of all images and videos used in the build. Add another object with correct info when adding another video or image.
		"media": [
			{
				"mediaType": "video",
				"poster": process.env.PUBLIC_URL + "/images/forte/yt-gt-video.jpg",
				"id": "eKxOyzhZmm8",
				"title": `Irony | 2022 Kia Forte GT Compact Sedan`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/forte/interior-image.jpg",
				"id": process.env.PUBLIC_URL + "/images/forte/interior-image.jpg",
				"title": `111 cubic feet of interior volume`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/forte/dash-image.jpg",
				"id": process.env.PUBLIC_URL + "/images/forte/dash-image.jpg",
				"title": `Driver-centric cockpit`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/forte/tailpipe-image.jpg",
				"id": process.env.PUBLIC_URL + "/images/forte/tailpipe-image.jpg",
				"title": `Sport-tuned dual exhaust`
			}
		],
		//This is the array for the car model dropdown/accordian menu component. ** Make sure id's and info match.
		"carListMenu":[
			{
				"title": "2022 Forte",
				"id": "C",
				"image": process.env.PUBLIC_URL + "/images/forte/menupics/C.png",
				"modelYear": "2022"
			}
		], 
		"footerLinks": {
			"facebook": "https://facebook.com/kia",
			"instagram": "https://instagram.com/kiausa",
			"twitter": "https://twitter.com/kia",
			"youtube": "https://youtube.com/user/KiaMotorsAmerica",
			"terms": "https://www.kia.com/us/en/terms-of-service",
			"privacy": "https://www.kia.com/us/en/privacy"
		},
		"offerDetails": {
			"available": true,
			"jellyBean": "",
			"disclaimer": `<p>GT with GT2 Package shown. Optional features shown. Some features may vary.</p>`,
			"links": {
				"signUp": "https://www.kia.com/us/en/receive-update?vehicle=forte&year=2021",
				"pdf": "https://www.kia.com/content/dam/kia/us/brochures/forte_2022_brochure.pdf",
				"modelPage": "https://www.kia.com/us/en/",
				"getQuote": "https://www.kia.com/us/en/get-a-quote?",
				"inventory":"https://www.kia.com/us/en/inventory/landing?"
			}
		}
	},

	// EV6 //


	"ev6": {
		"modelIntro": {
			"intro": false,
			"portraitUrl": "",
			"landscapeUrl": "",
		},
		"offerParams": {
			// Autoever API GET endpoint
			"apiUrl": "https://services.kia.com/KiaRestLite/rest/offers/getIncentiveByModelAndState.jsonp?",
			"langcode": "en",
			// Full Title of multi model type
			"smName":"EV6",
			"modelYear": "2022",
			// Offer types with the associated id's used in the API call.
			"offerTypes": {
				"Finance": "1",
				"Lease": "2",
				"Cash": "3",
				"Military": "13"
			}
		},
		"header": {
			"heroImage": process.env.PUBLIC_URL + "/images/ev6/homehero.jpg",
			"tagline":"The all-new EV6.<br/>Live fully charged.",
			"awards": [/*process.env.PUBLIC_URL + "/images/ev6/award_1.svg"*/]
		},
		"links": {
			"available": true,
			"releaseDate": "",
			"pdf": "https://www.kia.com/content/dam/kia/us/brochures/KMD22_10709_EV6_Brochure_r14_MED.pdf",
			"kiaUrl": "https://www.kia.com/us/en/",
			"awards": [/*process.env.PUBLIC_URL + "/images/ev6/award_1.svg", process.env.PUBLIC_URL + "/images/ev6/award_2.svg"*/]
		},
		//This is the array of all images and videos used in the build. Add another object with correct info when adding another video or image.
		"media": [
			{
				"mediaType": "video",
				"poster": process.env.PUBLIC_URL + "/images/ev6/ev6_video_1.jpg",
				"id": "HoNMz_OV_dI",
				"title": `Robo Dog | The All-Electric Kia EV6<sup aria-hidden="true">1</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/ev6/ev6_image_1.jpg",
				"id": process.env.PUBLIC_URL + "/images/ev6/ev6_image_1.jpg",
				"title": `Ability to fast-charge at certain commercial charging stations<sup aria-hidden="true">2</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/ev6/ev6_image_2.jpg",
				"id": process.env.PUBLIC_URL + "/images/ev6/ev6_image_2.jpg",
				"title": `Available dual-motor e-AWD system<sup aria-hidden="true">3</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/ev6/ev6_image_3.jpg",
				"id": process.env.PUBLIC_URL + "/images/ev6/ev6_image_3.jpg",
				"title": `Spacious five-passenger crossover<sup aria-hidden="true">4</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/ev6/ev6_image_4.jpg",
				"id": process.env.PUBLIC_URL + "/images/ev6/ev6_image_4.jpg",
				"title": `Available augmented reality head-up display<sup aria-hidden="true">5</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/ev6/ev6_image_5.jpg",
				"id": process.env.PUBLIC_URL + "/images/ev6/ev6_image_5.jpg",
				"title": `Available remote Smart Parking Assist<sup aria-hidden="true">6</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/ev6/ev6_image_6.jpg",
				"id": process.env.PUBLIC_URL + "/images/ev6/ev6_image_6.jpg",
				"title": `Available onboard power generator<sup aria-hidden="true">7</sup>`

			}


		],
		//This is the array for the car model dropdown/accordian menu component. ** Make sure id's and info match.
		"carListMenu":[
			{
				"title": "2022 EV6",
				"id": "N",
				"image": process.env.PUBLIC_URL + "/images/ev6/menupics/EV6.png",
				"modelYear": "2022"
			}
		], 
		"footerLinks": {
			"facebook": "https://facebook.com/kia",
			"instagram": "https://instagram.com/kiausa",
			"twitter": "https://twitter.com/kia",
			"youtube": "https://youtube.com/user/KiaMotorsAmerica",
			"terms": "https://www.kia.com/us/en/terms-of-service",
			"privacy": "https://www.kia.com/us/en/privacy"
		},
		"offerDetails": {
			"available": true,
			"jellyBean": "",
			"disclaimer": `<p><sup>1</sup>GT-Line e-AWD shown. AWD is an available feature. No system, no matter how advanced, can compensate for all driver error and/or driving conditions. Always drive safely.</p><p><sup>2</sup>GT-Line e-AWD shown. The EV6 offers both standard and, when time is short, DC fast charging. DC fast charging impacts battery durability. Vehicle can charge from a 10% state of charge up to 80% in 18 mins., using a 350kW DC fast charger. DC fast charger available at certain commercial charging locations. Charging time will vary.</p><p><sup>3</sup>GT-Line e-AWD shown. AWD is an available feature. No system, no matter how advanced, can compensate for all driver error and/or driving conditions. Always drive safely.</p><p><sup>4</sup>GT-Line e-AWD shown.</p><p><sup>5</sup>GT-Line e-AWD shown. Augmented reality head-up display is available on select trims. Failure to pay attention to travel conditions and vehicle operation could result in loss of vehicle control. Always drive safely and use caution.</p><p><sup>6</sup>Wind (left) & GT-Line e-AWD (right) shown. Optional feature available only on certain trims. It is the driver’s responsibility to ensure it is safe to use the Remote Smart Parking Assist feature. RSPA is not a substitute for safe driving and may not detect all objects around or behind vehicle. Always drive safely and use caution.</p><p><sup>7</sup>GT-Line e-AWD shown. Optional feature available only on certain trims.</p>`,
			"links": {
				"signUp": "https://www.kia.com/us/en/receive-update?vehicle=ev6&year=2023",
				"pdf": "https://www.kia.com/content/dam/kia/us/brochures/KMD22_10709_EV6_Brochure_r14_MED.pdf",
				"modelPage": "https://www.kia.com/us/en/",
				"getQuote": "https://www.kia.com/us/en/get-a-quote?",
				"inventory":"https://www.kia.com/us/en/inventory/landing?"
			}
		}
	},

/// Sportage ///

	"sportage": {
		"modelIntro": {
			"intro": false,
			"portraitUrl": "",
			"landscapeUrl": "",
		},
		"offerParams": {
			// Autoever API GET endpoint
			"apiUrl": "https://services.kia.com/KiaRestLite/rest/offers/getIncentiveByModelAndState.jsonp?",
			"langcode": "en",
			// Full Title of multi model type
			"smName":"Sportage",
			"modelYear": "2023",
			// Offer types with the associated id's used in the API call.
			"offerTypes": {
				"Finance": "1",
				"Lease": "2",
				"Cash": "3",
				"Military": "13"
			}
		},
		"header": {
			"heroImage": process.env.PUBLIC_URL + "/images/sportage/homehero.jpg",
			"tagline":"The all-new Sportage.<br/>Move in new ways.",
			"awards": []
		},
		"links": {
			"available": true,
			"releaseDate": "",
			"pdf": "https://www.kia.com/content/dam/kia/us/brochures/brochure_sportage_2023.pdf",
			"kiaUrl": "https://www.kia.com/us/en/",
			"awards": []
		},
		//This is the array of all images and videos used in the build. Add another object with correct info when adding another video or image.
		"media": [
			{
				"mediaType": "video",
				"poster": process.env.PUBLIC_URL + "/images/sportage/sportage_video_1.jpg",
				"id": "aIEDWGT7gsk",
				"title": `2022 Kia Lineup of Capable SUVs & Crossovers<sup aria-hidden="true">1</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/sportage/sportage_image_1.jpg",
				"id": process.env.PUBLIC_URL + "/images/sportage/sportage_image_1.jpg",
				"title": `Sportage 2.5L GDI, Turbo-Hybrid, Turbo Plug-In Hybrid<sup aria-hidden="true">2</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/sportage/sportage_image_2.jpg",
				"id": process.env.PUBLIC_URL + "/images/sportage/sportage_image_2.jpg",
				"title": `Ultra-spacious, versatile cabin<sup aria-hidden="true">3</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/sportage/sportage_image_3.jpg",
				"id": process.env.PUBLIC_URL + "/images/sportage/sportage_image_3.jpg",
				"title": `Driver-focused cockpit<sup aria-hidden="true">4</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/sportage/sportage_image_4.jpg",
				"id": process.env.PUBLIC_URL + "/images/sportage/sportage_image_4.jpg",
				"title": `All-new 2.5L GDI Sportage X-Pro<sup aria-hidden="true">5</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/sportage/sportage_image_5.jpg",
				"id": process.env.PUBLIC_URL + "/images/sportage/sportage_image_5.jpg",
				"title": `All-new Sportage Turbo-Hybrid<sup aria-hidden="true">6</sup>`
			},
			{
				"mediaType": "image",
				"poster": process.env.PUBLIC_URL + "/images/sportage/sportage_image_6.jpg",
				"id": process.env.PUBLIC_URL + "/images/sportage/sportage_image_6.jpg",
				"title": `Available Remote Smart Parking Assist<sup aria-hidden="true">7</sup>`

			}


		],
		//This is the array for the car model dropdown/accordian menu component. ** Make sure id's and info match.
		"carListMenu":[
			{
				"title": "2023 Sportage (Gas)",
				"id": "4",
				"image": process.env.PUBLIC_URL + "/images/sportage/menupics/4.png",
				"modelYear": "2023"
			},
			{
				"title": "2023 Sportage HEV",
				"id": "S",
				"image": process.env.PUBLIC_URL + "/images/sportage/menupics/S.png",
				"modelYear": "2023"
			},
			{
				"title": "2023 Sportage PHEV",
				"id": "R",
				"image": process.env.PUBLIC_URL + "/images/sportage/menupics/R.png",
				"modelYear": "2023"
			}
		], 
		"footerLinks": {
			"facebook": "https://facebook.com/kia",
			"instagram": "https://instagram.com/kiausa",
			"twitter": "https://twitter.com/kia",
			"youtube": "https://youtube.com/user/KiaMotorsAmerica",
			"terms": "https://www.kia.com/us/en/terms-of-service",
			"privacy": "https://www.kia.com/us/en/privacy"
		},
		"offerDetails": {
			"available": true,
			"jellyBean": "",
			"disclaimer": `<p><sup>1</sup>Turbo Plug-In Hybrid X-Line AWD shown.</p><p><sup>2</sup>Left: 2.5L GDI Sportage X-Pro Prestige AWD shown. Middle: Turbo-Hybrid EX AWD shown. Right: Turbo Plug-In Hybrid X-Line AWD shown.</p><p><sup>3</sup>2.5L GDI Sportage X-Pro Prestige AWD shown.</p><p><sup>4</sup>Turbo-Hybrid EX AWD shown.</p><p><sup>5</sup>2.5L GDI Sportage X-Pro Prestige AWD shown.</p><p><sup>6</sup>Turbo-Hybrid EX AWD shown.</p><p><sup>7</sup>Turbo Plug-In Hybrid X-Line Prestige AWD shown. Remote Smart Parking Assist is a suppelemental feature. The driver is responsible for ensuring the safe operation of the vehicle and that there are no objects or people around the vehicle.</p>`,
			"links": {
				"signUp": "https://www.kia.com/us/en/receive-update?vehicle=sportage&year=2023",
				"pdf": "https://www.kia.com/content/dam/kia/us/brochures/brochure_sportage_2023.pdf",
				"modelPage": "https://www.kia.com/us/en/",
				"getQuote": "https://www.kia.com/us/en/get-a-quote?",
				"inventory":"https://www.kia.com/us/en/inventory/landing?"
			}
		}
	},

/// Niro ///	

"niro": {
	"modelIntro": {
		"intro": false,
		"portraitUrl": "",
		"landscapeUrl": "",
	},
	"offerParams": {
		// Autoever API GET endpoint
		"apiUrl": "https://services.kia.com/KiaRestLite/rest/offers/getIncentiveByModelAndState.jsonp?",
		"langcode": "en",
		// Full Title of multi model type
		"smName":"Niro",
		"modelYear": "2023",
		// Offer types with the associated id's used in the API call.
		"offerTypes": {
			"Finance": "1",
			"Lease": "2",
			"Cash": "3",
			"Military": "13"
		}
	},
	"header": {
		"heroImage": process.env.PUBLIC_URL + "/images/niro/homehero.jpg",
		"tagline":"The all-new Niro.<br/>A force for better.",
		"awards": []
	},
	"links": {
		"available": true,
		"releaseDate": "",
		"pdf": "https://www.kia.com/content/dam/kia/us/brochures/brochure_niro-suv-hev-2023.pdf",
		"kiaUrl": "https://www.kia.com/us/en/",
		"awards": []
	},
	//This is the array of all images and videos used in the build. Add another object with correct info when adding another video or image.
	"media": [
		{
			"mediaType": "video",
			"poster": process.env.PUBLIC_URL + "/images/niro/niro_video_1.jpg",
			"id": "dd-CmzXyZRE",
			"title": `Ends of the Earth | 2022 Niro SUV Family Lineup`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/niro/niro_image_1.jpg",
			"id": process.env.PUBLIC_URL + "/images/niro/niro_image_1.jpg",
			"title": `Niro Hybrid, EV, Plug-In Hybrid`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/niro/niro_image_2.jpg",
			"id": process.env.PUBLIC_URL + "/images/niro/niro_image_2.jpg",
			"title": `Best-in-class spaciousness<sup aria-hidden="true">1</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/niro/niro_image_3.jpg",
			"id": process.env.PUBLIC_URL + "/images/niro/niro_image_3.jpg",
			"title": `Available Duel Panoramic 10.25-inch screens<sup aria-hidden="true">2</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/niro/niro_image_4.jpg",
			"id": process.env.PUBLIC_URL + "/images/niro/niro_image_4.jpg",
			"title": `Niro Hybrid with best-in-class 53 MPG<sup aria-hidden="true">3</sup><br /><span class='subtext'>HEV LX, EX, & SX receive 53 MPG<sup aria-hidden="true">3</sup>, HEV SX Touring, shown, gets 49 MPG<sup aria-hidden="true">3</sup>.</span>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/niro/niro_image_5.jpg",
			"id": process.env.PUBLIC_URL + "/images/niro/niro_image_5.jpg",
			"title": `Niro EV with 253 EPA-estimated range<sup aria-hidden="true">4</sup><br /><span class='subtext'>Charger not included.</span>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/niro/niro_image_6.jpg",
			"id": process.env.PUBLIC_URL + "/images/niro/niro_image_6.jpg",
			"title": `LED rear combination lamps`

		}


	],
	//This is the array for the car model dropdown/accordian menu component. ** Make sure id's and info match.
	"carListMenu":[
		{
			"title": "2023 Niro HEV",
			"id": "G",
			"image": process.env.PUBLIC_URL + "/images/niro/menupics/G.png",
			"modelYear": "2023"
		},
		{
			"title": "2023 Niro PHEV",
			"id": "F",
			"image": process.env.PUBLIC_URL + "/images/niro/menupics/F.png",
			"modelYear": "2023"
		},
		{
			"title": "2023 Niro EV",
			"id": "V",
			"image": process.env.PUBLIC_URL + "/images/niro/menupics/V.png",
			"modelYear": "2023"
		}
	], 
	"footerLinks": {
		"facebook": "https://facebook.com/kia",
		"instagram": " https://www.instagram.com/kiausa",
		"twitter": "https://twitter.com/kia",
		"youtube": "https://www.youtube.com/user/kiamotorsamerica",
		"terms": "https://www.kia.com/us/en/terms-of-service",
		"privacy": "https://www.kia.com/us/en/privacy"
	},
	"offerDetails": {
		"available": true,
		"jellyBean": "",
		"disclaimer": `<p><sup>1</sup>Comparison based on passenger volume of available 2022 and 2023 Entry CUV class vehicles using manufacturer-supplied information as of August 2022.</p>
		               <p><sup>2</sup>Distracted driving can result in a loss of vehicle control. When operating a vehicle, never use a handheld device or vehicle system that takes your focus away from safe vehicle operation. Navigation is for information purposes only, and Kia does not make an warrenties about the accuracy of the information.</p>
					   <p><sup>3</sup>HEV LX, EX, and SX 53 MPG claim based on comparison of EPA combined fuel economy estimates for MPG, of Entry CUV vehicle class as of July 2022. HEV SX Touring 49 MPG shown not included in comparison claim. Actual mileage and range will vary with options, driving conditions, driving habits, vehicle maintenance, charging practice, battery age, weather, temperature, and your vehicle’s condition. Battery capacity will decrease with time and use. For more information on MPG, please see <a href="https://fueleconomy.gov/" target="_blank"><u>www.fueleconomy.gov</u></a>.</p>
					   <p><sup>4</sup>EPA-estimated 253-mile range based on a full battery charge. Actual mileage and range will vary with options, driving conditions, driving habits, vehicle maintenance, charging practice, battery age, weather, temperature, and your vehicle's condition. Battery capacity will decrease with time and use. For more information on range, please see <a href="https://fueleconomy.gov/" target="_blank"><u>www.fueleconomy.gov</u></a>.</p>`,
		"links": {
			"signUp": "https://www.kia.com/us/en/receive-update?vehicle=niro&year=2023",
			"pdf": "https://www.kia.com/content/dam/kia/us/brochures/brochure_niro-suv-hev-2023.pdf",
			"modelPage": "https://www.kia.com/us/en/",
			"getQuote": "https://www.kia.com/us/en/get-a-quote?",
			"inventory":"https://www.kia.com/us/en/inventory/landing?"
		}
	}
},
/// Telluride ///	

"telluride": {
	"modelIntro": {
		"intro": false,
		"portraitUrl": "",
		"landscapeUrl": "",
	},
	"offerParams": {
		// Autoever API GET endpoint
		"apiUrl": "https://services.kia.com/KiaRestLite/rest/offers/getIncentiveByModelAndState.jsonp?",
		"langcode": "en",
		// Full Title of multi model type
		"smName":"Telluride",
		"modelYear": "2023",
		// Offer types with the associated id's used in the API call.
		"offerTypes": {
			"Finance": "1",
			"Lease": "2",
			"Cash": "3",
			"Military": "13"
		}
	},
	"header": {
		"heroImage": process.env.PUBLIC_URL + "/images/telluride/homehero.jpg",
		"tagline":"The new Telluride.<br/>For roads yet<br/>imagined.",
		"awards": []
	},
	"links": {
		"available": true,
		"releaseDate": "",
		"pdf": "https://www.kia.com/content/dam/kia/us/brochures/KIA23_11311-3_Telluride_Brochure_r13_UNTAGGED_HI.pdf",
		"kiaUrl": "https://www.kia.com/us/en/",
		"awards": []
	},
	//This is the array of all images and videos used in the build. Add another object with correct info when adding another video or image.
	"media": [
		{
			"mediaType": "video",
			"poster": process.env.PUBLIC_URL + "/images/telluride/telluride_video_1.jpg",
			"id": "KXJTJUYTlqs",
			"title": `A New Elevation | The New 2023 Kia Telluride Mid-Size SUV`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/telluride/telluride_image_1.jpg",
			"id": process.env.PUBLIC_URL + "/images/telluride/telluride_image_1.jpg",
			"title": `New X-Pro with available Active On-Demand AWD<sup aria-hidden="true">1</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/telluride/telluride_image_2.jpg",
			"id": process.env.PUBLIC_URL + "/images/telluride/telluride_image_2.jpg",
			"title": `Class-leading interior space<sup aria-hidden="true">2</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/telluride/telluride_image_3.jpg",
			"id": process.env.PUBLIC_URL + "/images/telluride/telluride_image_3.jpg",
			"title": `Dual Panoramic 12.3-inch screens<sup aria-hidden="true">3</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/telluride/telluride_image_4.jpg",
			"id": process.env.PUBLIC_URL + "/images/telluride/telluride_image_4.jpg",
			"title": `Available increased ground clearance`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/telluride/telluride_image_5.jpg",
			"id": process.env.PUBLIC_URL + "/images/telluride/telluride_image_5.jpg",
			"title": `Up to 5,500 Ibs. of available towing capability<sup aria-hidden="true">4</sup>`
		}


	],
	//This is the array for the car model dropdown/accordian menu component. ** Make sure id's and info match.
	"carListMenu":[
		{
			"title": "2023 Telluride",
			"id": "J",
			"image": process.env.PUBLIC_URL + "/images/telluride/menupics/J.png",
			"modelYear": "2023"
		}
	], 
	"footerLinks": {
		"facebook": "https://facebook.com/kia",
		"instagram": "https://www.instagram.com/kiausa",
		"twitter": "https://twitter.com/kia",
		"youtube": "https://www.youtube.com/user/kiamotorsamerica",
		"terms": "https://www.kia.com/us/en/terms-of-service",
		"privacy": "https://www.kia.com/us/en/privacy"
	},
	"offerDetails": {
		"available": true,
		"jellyBean": "",
		"disclaimer": `<p><sup>1</sup>Never drive through water without confirming depth is below the wheel hub. See Owner’s Manual for warnings and instructions. No system, no matter how advanced, can compensate for all driver errors and or driving conditions. Always drive safely.</p>
		               <p><sup>2</sup>Comparison claims based on mid-size SUV-class segmentation using manufacturer-supplied data as of July 2022.</p>
					   <p><sup>3</sup>Distracted driving can result in a loss of vehicle control. When operating a vehicle, never use a handheld device or vehicle system that takes your focus away from safe vehicle operation. Navigation is for information purposes only, and Kia does not make any warranties about the accuracy of the information.</p>
					   <p><sup>4</sup>Towing requires additional equipment. See Owner’s Manual for towing capacity, additional instructions, and warnings. Always use caution while towing.</p>`,
		"links": {
			"signUp": "https://www.kia.com/us/en/receive-update?vehicle=telluride&year=2023",
			"pdf": "https://www.kia.com/content/dam/kia/us/brochures/KIA23_11311-3_Telluride_Brochure_r13_UNTAGGED_HI.pdf",
			"modelPage": "https://www.kia.com/us/en/",
			"getQuote": "https://www.kia.com/us/en/get-a-quote?",
			"inventory":"https://www.kia.com/us/en/inventory/landing?"
		}
	}
},

/// EV9 ///	

"ev9": {
	"modelIntro": {
		"intro": false,
		"portraitUrl": process.env.PUBLIC_URL + "/images/ev9/video/portrait.mp4",
		"landscapeUrl": process.env.PUBLIC_URL + "/images/ev9/video/landscape.mp4"
	},
	"offerParams": {
		// Autoever API GET endpoint
		"apiUrl": "https://services.kia.com/KiaRestLite/rest/offers/getIncentiveByModelAndState.jsonp?",
		"langcode": "en",
		// Full Title of multi model type
		"smName":"EV9",
		"modelYear": "2024",
		// Offer types with the associated id's used in the API call.
		"offerTypes": {
			"Finance": "1",
			"Lease": "2",
			"Cash": "3",
			"Military": "13"
		}
	},
	"header": {
		"heroImage": process.env.PUBLIC_URL + "/images/ev9/homehero.jpg",
		"tagline":"The all-new,<br>all-electric Kia EV9.",
		"awards": []
	},
	"links": {
		"available": true,
		"releaseDate": "",
		"reservationLink" : "https://www.kia.com/us/en/ev9#reservationprogram",
		"pdf": "https://www.kia.com/content/dam/kia/us/brochures/KIA24_EV9_Brochure.pdf",
		"kiaUrl": "https://www.kia.com/us/en/",
		"awards": []
	},
	//This is the array of all images and videos used in the build. Add another object with correct info when adding another video or image.
	"media": [
		{
			"mediaType": "video",
			"poster": process.env.PUBLIC_URL + "/images/ev9/ev9_video_1.jpg",
			"id": "-7Q17UopgT4",
			"title": `The all-new EV9.`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/ev9/ev9_image_1.jpg",
			"id": process.env.PUBLIC_URL + "/images/ev9/ev9_image_1.jpg",
			"title": `Available 379 hp, 516 lb.-ft. of torque, and 0-60 mph in 4.5 seconds.<sup aria-hidden="true">1</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/ev9/ev9_image_2.jpg",
			"id": process.env.PUBLIC_URL + "/images/ev9/ev9_image_2.jpg",
			"title": `Seating for 6 or 7 with best-in-class 2nd-row legroom.<sup aria-hidden="true">2</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/ev9/ev9_image_3.jpg",
			"id": process.env.PUBLIC_URL + "/images/ev9/ev9_image_3.jpg",
			"title": `Up to 304 miles of all-electric range.<sup aria-hidden="true">3, 4</sup> Charge from 10% to 80% in 24 min. on commercial 350 kW DC fast chargers.<sup aria-hidden="true">5</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/ev9/ev9_image_4.jpg",
			"id": process.env.PUBLIC_URL + "/images/ev9/ev9_image_4.jpg",
			"title": `Available Dual-Motor e-AWD with torque-vectoring and AWD Lock Mode<sup aria-hidden="true">6</sup> and 7.8 inches of ground clearance.`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/ev9/ev9_image_5.jpg",
			"id": process.env.PUBLIC_URL + "/images/ev9/ev9_image_5.jpg",
			"title": `Next-level tech with 29.6-inch Combined Panoramic display.<sup aria-hidden="true">7</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/ev9/ev9_image_6.jpg",
			"id": process.env.PUBLIC_URL + "/images/ev9/ev9_image_6.jpg",
			"title": `Up to 5,000 lbs. of available towing capability.<sup aria-hidden="true">4, 8</sup>`
		}


	],
	//This is the array for the car model dropdown/accordian menu component. ** Make sure id's and info match.
	"carListMenu":[
		{
			"title": "2024 EV9",
			"id": "P",
			"image": process.env.PUBLIC_URL + "/images/ev9/menupics/EV9.png",
			"modelYear": "2024"
		}
	], 
	"footerLinks": {
		"facebook": "https://facebook.com/kia",
		"instagram": "https://www.instagram.com/kiausa",
		"twitter": "https://twitter.com/kia",
		"youtube": "https://www.youtube.com/user/kiamotorsamerica",
		"terms": "https://www.kia.com/us/en/terms-of-service",
		"privacy": "https://www.kia.com/us/en/privacy"
	},
	"offerDetails": {
		"available": true,
		"reservationLink" : "https://www.kia.com/us/en/ev9#reservationprogram",
		"jellyBean": "",
		"disclaimer": `<p><sup>1</sup>Acceleration claim based on AMCI Testing Certified results of 2024 EV9 GT-Line AWD on dry pavement ESC on using typical industry methodology with 1-ft. rollout. Results reported August 2023. Actual results may vary depending on options, driving conditions, driving habits, and your vehicle’s condition. Verification of these results should not be attempted. Always drive safely and obey all traffic laws.</p>
		               <p><sup>2</sup>Comparison based on publicly available data regarding 2nd-row legroom in 2023 Mainstream EV SUVs as of October 2023. Mainstream EV SUV class as defined by Kia segmentation.</p>
					   <p><sup>3</sup>Based on EPA estimates on a full battery charge. Range of 304 miles is for Light Long Range RWD trim. Actual range will vary with options, driving conditions, driving habits, vehicle maintenance, charging practice, battery age, weather, temperature, and your vehicle’s condition. Battery capacity will decrease with time and use. For more information on range, please see <a href="https://www.fueleconomy.gov" target="_blank"/>www.fueleconomy.gov</a>. Level 2 charger sold separately and not included.</p>
					   <p><sup>4</sup>Optional features available only on certain trims. See <a href="https://www.kia.com/content/dam/kia/us/brochures/KIA24_EV9_Brochure.pdf" target="_blank">EV9 PDF brochure</a> Features page for availability.</p>
					   <p><sup>5</sup>Charge time with 350 kW fast charger. Actual charge time will vary with options, driving conditions, driving habits, vehicle maintenance, charging practice, battery age, weather, temperature, and your vehicle’s condition. Battery capacity will decrease with time and use. Frequent use of DC fast charging can negatively impact battery performance and durability, and Kia recommends minimizing use of DC fast charging.</p>
					   <p><sup>6</sup>No system, no matter how advanced, can compensate for all driver errors and/or driving conditions. Always drive safely.</p>
					   <p><sup>7</sup>Distracted driving can result in a loss of vehicle control. When operating a vehicle, never use a handheld device or vehicle system that takes your focus away from safe vehicle operation. Navigation is for information purposes only, and Kia does not make any warranties about the accuracy of the information.</p>
					   <p><sup>8</sup>Towing may significantly reduce electric range and requires additional equipment. See Owner’s Manual for towing capacity, additional instructions, and warnings. Always use caution while towing.</p>`,
		"links": {
			"signUp": "https://www.kia.com/us/en/receive-update?vehicle=ev9&year=2024",
			"pdf": "https://www.kia.com/content/dam/kia/us/brochures/KIA24_EV9_Brochure.pdf",
			"modelPage": "https://www.kia.com/us/en/",
			"getQuote": "https://www.kia.com/us/en/get-a-quote?",
			"inventory":"https://www.kia.com/us/en/inventory/landing?"
		}
	}
},

/// k4 ///	

"k4": {
	"modelIntro": {
		"intro": false,
		"portraitUrl": process.env.PUBLIC_URL + "/images/k4/video/portrait.mp4",
		"landscapeUrl": process.env.PUBLIC_URL + "/images/k4/video/landscape.mp4"
	},
	"offerParams": {
		// Autoever API GET endpoint
		"apiUrl": "https://services.kia.com/KiaRestLite/rest/offers/getIncentiveByModelAndState.jsonp?",
		"langcode": "en",
		// Full Title of multi model type
		"smName":"K4",
		"modelYear": "2025",
		// Offer types with the associated id's used in the API call.
		"offerTypes": {
			"Finance": "1",
			"Lease": "2",
			"Cash": "3",
			"Military": "13"
		}
	},
	"header": {
		"heroImage": process.env.PUBLIC_URL + "/images/k4/homehero.jpg",
		"tagline":"The all-new<br>Kia K4.",
		"awards": []
	},
	"links": {
		"available": true,
		"releaseDate": "",
		"reservationLink" : "https://www.kia.com/us/en/ev9#reservationprogram",
		"pdf": "https://www.kia.com/content/dam/kia/us/brochures/2025_K4_Brochure.pdf",
		"kiaUrl": "https://www.kia.com/us/en/",
		"awards": []
	},
	//This is the array of all images and videos used in the build. Add another object with correct info when adding another video or image.
	"media": [
		{
			"mediaType": "video",
			"poster": process.env.PUBLIC_URL + "/images/k4/k4_video_1.mp4",
			"id": "B_AXkprnJ1Q",
			"title": `The perfect balance of innovative engineering and bold design.`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/k4/k4_image_1.jpg",
			"id": process.env.PUBLIC_URL + "/images/k4/k4_image_1.jpg",
			"title": `An available 1.6-liter Turbo engine delivering an impressive 190 horsepower.`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/k4/k4_image_2.jpg",
			"id": process.env.PUBLIC_URL + "/images/k4/k4_image_2.jpg",
			"title": `Spacious cabin with class-leading 2nd-row legroom.<sup aria-hidden="true">1</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/k4/k4_image_3.jpg",
			"id": process.env.PUBLIC_URL + "/images/k4/k4_image_3.jpg",
			"title": `Striking Star Map LED front and rear lights.`
		},
		{
			"mediaType": "mp4Loop",
			"poster": process.env.PUBLIC_URL + "/images/k4/k4_video_2.mp4",
			"id": process.env.PUBLIC_URL + "/images/k4/k4_video_2.mp4",
			"title": `29 collision-avoidance and assist features.<sup aria-hidden="true">2</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/k4/k4_image_4.jpg",
			"id": process.env.PUBLIC_URL + "/images/k4/k4_image_4.jpg",
			"title": `Fastback roofline and wide stance.`
		},
		{
			"mediaType": "mp4Loop",
			"poster": process.env.PUBLIC_URL + "/images/k4/k4_video_3.mp4",
			"id": process.env.PUBLIC_URL + "/images/k4/k4_video_3.mp4",
			"title": `Standard wireless Apple CarPlay,<sup aria-hidden="true">®</sup><sup aria-hidden="true">3</sup> Android Auto,<sup aria-hidden="true">™</sup><sup aria-hidden="true">4</sup> and wireless charging.<sup aria-hidden="true">5</sup>`
		},
		{
			"mediaType": "image",
			"poster": process.env.PUBLIC_URL + "/images/k4/k4_image_5.jpg",
			"id": process.env.PUBLIC_URL + "/images/k4/k4_image_5.jpg",
			"title": ``
		}

	],
	//This is the array for the car model dropdown/accordian menu component. ** Make sure id's and info match.
	"carListMenu":[
		{
			"title": "2025 K4",
			"id": "2",
			"image": process.env.PUBLIC_URL + "/images/k4/menupics/2.png",
			"modelYear": "2025"
		}
	], 
	"footerLinks": {
		"facebook": "https://facebook.com/kia",
		"instagram": "https://www.instagram.com/kiausa",
		"twitter": "https://twitter.com/kia",
		"youtube": "https://www.youtube.com/user/kiamotorsamerica",
		"terms": "https://www.kia.com/us/en/terms-of-service",
		"privacy": "https://www.kia.com/us/en/privacy"
	},
	"offerDetails": {
		"available": true,
		"reservationLink" : "https://www.kia.com/us/en/ev9#reservationprogram",
		"jellyBean": "",
		"disclaimer": `<p><sup>1</sup>Comparison based on publicly available data regarding rear legroom in 2024 Compact cars of June 2024. Compact car class as defined by Kia segmentation.</p>
		               <p><sup>2</sup>These features are not substitutes for safe driving and may not detect all objects surrounding vehicle. Always drive safely and use caution.</p>
					   <p><sup>3</sup>Apple CarPlay,<sup>®</sup> Siri,<sup>®</sup> and iPhone<sup>®</sup> are registered trademarks of Apple Inc.</p>
					   <p><sup>4</sup>Vehicle user interface is a product of Google and its terms and privacy statement apply. Compatible Android phone and compatible active data plan required. Data plan rates apply. Android, Google Play, and YouTube are trademarks of Google LLC.</p>
					   <p><sup>5</sup>Qi-enabled smartphone required.</p>`,
		"links": {
			"signUp": "https://www.kia.com/us/en/receive-update?vehicle=k4&year=2025",
			"pdf": "https://www.kia.com/content/dam/kia/us/brochures/2025_K4_Brochure.pdf",
			"modelPage": "https://www.kia.com/us/en/",
			"getQuote": "https://www.kia.com/us/en/get-a-quote?",
			"inventory":"https://www.kia.com/us/en/inventory/landing?"
		}
	}
}

	//// Name
	//// ** Add new object if you're adding another multi-model type.

}

]